import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getSectionsThunk,
  updateSectionThunk,
  uploadFileThunk,
} from "./sectionsThunk";

const initialState = {
  isLoading: false,
  sections: null,
};

export const httpGetSections = createAsyncThunk(
  "sections/getSections",
  getSectionsThunk
);

export const httpUpdateSectionThunk = createAsyncThunk(
  "pageData/updateSection",
  updateSectionThunk
);

export const httpUploadFile = createAsyncThunk(
  "pageData/uploadFile",
  uploadFileThunk
);

const sectionsSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetSections.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetSections.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.sections = payload.layout;
    },
    [httpGetSections.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateSectionThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateSectionThunk.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateSectionThunk.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUploadFile.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUploadFile.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUploadFile.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = sectionsSlice.actions;
export default sectionsSlice.reducer;
