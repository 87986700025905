import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllEmailThunk,
  addEmailThunk,
  updateEmailThunk,
  deleteEmailThunk,
} from "./emailThunk";

const initialState = {
  isLoading: false,
  email: null,
};

export const httpGetAllEmail = createAsyncThunk(
  "email/allEmail",
  getAllEmailThunk
);

export const httpAddEmail = createAsyncThunk(
  "email/updateEmail",
  addEmailThunk
);

export const httpUpdateEmail = createAsyncThunk(
  "email/updateEmail",
  updateEmailThunk
);

export const httpDeleteEmail = createAsyncThunk(
  "email/updateEmail",
  deleteEmailThunk
);

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllEmail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.emailIds = payload.email;
    },
    [httpGetAllEmail.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpAddEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [httpAddEmail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpAddEmail.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateEmail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateEmail.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpDeleteEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [httpDeleteEmail.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpDeleteEmail.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = emailSlice.actions;
export default emailSlice.reducer;
