import React, { useEffect, useState } from "react";

const FormNumber = (props) => {
  const {
    dom = {},
    label = "",
    error = "",
    errorMessage,
    preFix,
    postFix,
    errorClass = "",
    extraContent,
    wrapperClass = "",
    containerclass = "",
    fullwidth = false,
  } = props;

  const inputRef = React.createRef();
  const [value, setValue] = useState(dom?.value);

  useEffect(() => {
    dom.autoFocus && inputRef?.current?.focus();
  }, [dom.autoFocus, inputRef]);

  const handleChange = (event) => {
    const amount = event.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      setValue(amount);
      dom.onChange({ target: { value: amount } });
    }
  };

  return (
    <div className={`${wrapperClass ? wrapperClass : ""}`}>
      {label && (
        <div className="px-0">
          <label>{label}</label>
          {dom.required && <small className="btn-danger ml-1">*</small>}
        </div>
      )}
      <div
        className={`d-flex align-items-center position-relative ${
          containerclass ? containerclass : ""
        }`}
      >
        {preFix && preFix()}
        <input
          {...dom}
          ref={inputRef}
          autoComplete="off"
          className={`form-control ${dom.className} ${
            error ? "form-control-error" : ""
          } ${fullwidth ? "w-100" : ""}`}
          type="text"
          value={value}
          onChange={handleChange}
        />
        {postFix && postFix()}
        {extraContent && extraContent()}
      </div>
      {error ? (
        <div className="input-error-message">
          <span className={` ${errorClass}`}>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default FormNumber;
