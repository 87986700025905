import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addAdminToLocalStorage,
  addTokenToLocalStorage,
  getAdminFromLocalStorage,
  removeAdminFromLocalStorage,
} from "../../utils/localStorage";
import {
  verifyPasswordThunk,
  getOTPThunk,
  verifyOTPThunk,
  updateProfileThunk,
  getCountryCodeThunk,
} from "./adminThunk";

const initialState = {
  isLoading: false,
  isLoadingOtp: false,
  otpId: null,
  admin: getAdminFromLocalStorage(),
  verifyPass: false,
  otpPhoneVerificationError: false,
  otpPhoneVerificationSuccess: false,
  otpEmailVerificationError: false,
  otpEmailVerificationSuccess: false,
};

export const httpVerifyPassword = createAsyncThunk(
  "auth/verifyPassword",
  verifyPasswordThunk
);
export const httpGetOTP = createAsyncThunk("auth/getOTP", getOTPThunk);
export const httpVerifyOTP = createAsyncThunk("auth/verifyOTP", verifyOTPThunk);
export const httpUpdateProfile = createAsyncThunk(
  "admin/profile",
  updateProfileThunk
);
export const httpGetCountryCode = createAsyncThunk(
  "admin/profile",
  getCountryCodeThunk
);

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    logoutAdmin: (state, { payload }) => {
      state.otpId = null;
      state.admin = null;
      state.verifyPass = false;
      removeAdminFromLocalStorage();
      if (payload) {
        toast.success(payload);
      }
    },
    setVerifyPass: (state) => (state.verifyPass = false),
    clearOtpId: (state) => {
      state.otpId = null;
    },
  },
  extraReducers: {
    [httpVerifyPassword.pending]: (state) => {
      state.isLoading = true;
      state.verifyPass = false;
    },
    [httpVerifyPassword.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.verifyPass = true;
      state.admin = payload.admin;
      payload.admin && addAdminToLocalStorage(payload.admin);
      payload.admin && addTokenToLocalStorage(payload.admin.accessToken);
      toast.success(payload.msg);
    },
    [httpVerifyPassword.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.verifyPass = false;
      toast.error(payload);
    },
    [httpGetOTP.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetOTP.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.otpId = payload.otpId;
      toast.success(payload.msg);
    },
    [httpGetOTP.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpVerifyOTP.pending]: (state) => {
      state.isLoadingOtp = true;
    },
    [httpVerifyOTP.fulfilled]: (state, { payload }) => {
      const { msg, admin, mode } = payload;
      state.isLoadingOtp = false;
      state.admin = admin;
      if (mode === "email") {
        state.otpEmailVerificationError = false;
        state.otpEmailVerificationSuccess = true;
      }
      if (mode === "phone") {
        state.otpPhoneVerificationError = false;
        state.otpPhoneVerificationSuccess = true;
      }
      admin && addAdminToLocalStorage(admin);
      admin && addTokenToLocalStorage(admin.accessToken);
      toast.success(msg);
    },
    [httpVerifyOTP.rejected]: (state, { payload }) => {
      state.isLoadingOtp = false;
      if (payload.mode === "email") {
        state.otpEmailVerificationError = true;
        state.otpEmailVerificationSuccess = false;
      }
      if (payload.mode === "phone") {
        state.otpPhoneVerificationError = true;
        state.otpPhoneVerificationSuccess = false;
      }
      toast.error(payload.msg);
    },
  },
});

export const { logoutAdmin, setVerifyPass, clearOtpId } = adminSlice.actions;
export default adminSlice.reducer;
