import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "./global.css";

import "react-quill/dist/quill.snow.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Landing,
  SharedLayout,
  NotFound,
  ProtectedRoute,
  AllJob,
  AddPageContainer,
  // HomeContainer,
  AnalyticsContainer,
} from "./pages";
import SecurityContainer from "./pages/security/SecurityContainer";
import SecurityPassword from "./pages/security/SecurityPassword";
import LeadsContainer from "./pages/leads/LeadsContainer";
import EmailIdsContainer from "./pages/leads/EmailIdsContainer";
import ProductsContainer from "./pages/products/ProductsContainer";
import ProductsSettingContainer from "./pages/products/ProductsSettingContainer";
import AddProductForm from "./pages/products/AddProductForm";
import OrderContainer from "./pages/orders/OrdersContainer";
import OrdersSettingContainer from "./pages/orders/OrdersSettingContainer";
import ViewOrderContainer from "./pages/orders/ViewOrderContainer";
import CustomDialog from "./components/dialogs/Dialog";
import CustomDialog1 from "./components/dialogs/Dialog1";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas, fab, far);

const App = () => {
  return (
    <BrowserRouter>
      <CustomDialog></CustomDialog>
      <CustomDialog1></CustomDialog1>

      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route path="analytics" element={<AnalyticsContainer />} />
          <Route path="security" element={<SecurityContainer />} />
          <Route path="security-password" element={<SecurityPassword />} />
          <Route path="leads" element={<LeadsContainer />} />
          <Route path="leads-email" element={<EmailIdsContainer />} />
          <Route path="products" element={<ProductsContainer />} />
          <Route
            path="products-setting"
            element={<ProductsSettingContainer />}
          />
          <Route path="edit-product/:pId" element={<AddProductForm />} />
          <Route path="add-product/:uId/:pType" element={<AddProductForm />} />
          <Route path="orders" element={<OrderContainer />} />
          <Route path="orders-setting" element={<OrdersSettingContainer />} />
          <Route path="view-order/:oId" element={<ViewOrderContainer />} />
          <Route path=":pageName/:pageId" element={<AddPageContainer />} />
          <Route path="web-details" element={<AllJob />} />
        </Route>
        <Route path="/login" element={<Landing />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </BrowserRouter>
  );
};

export default App;
