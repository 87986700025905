import React, { useEffect, useState } from "react";
import FormInput from "../../components/form/FormInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { updatePassword } from "../../features/security/securitySlice";
import * as PasswordLib from "secure-random-password";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import {
  getAdminFromLocalStorage,
  addAdminToLocalStorage,
} from "../../utils/localStorage";
import styles from "./security.module.css";

function SecurityPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const { admin } = useSelector((store) => {
    return store.admin;
  });

  useEffect(() => {
    if (admin) {
      setPassword(admin.password);
    }
  }, [admin]);

  const regenerateId = (e) => {
    e && e.preventDefault();
    const pass = PasswordLib.randomPassword({ length: 10 });
    const data = {
      password: pass,
      reGenerate: true,
    };
    dispatch(updatePassword(data))
      .then((res) => {
        const admin = getAdminFromLocalStorage();
        admin.password = pass;
        addAdminToLocalStorage(admin);
        setPassword(pass);
        toast.success(res.payload.msg);
      })
      .catch((res) => toast.error(res.payload));
  };

  const copyText = (e) => {
    e && e.preventDefault();
    const copyText = document.getElementById("passInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(copyText.value);
    toast.success("Password copied");
  };

  const sendEmail = (e) => {
    e && e.preventDefault();
    const data = {
      password,
      reGenerate: false,
    };
    dispatch(updatePassword(data))
      .then((res) => toast.success(res.payload.msg))
      .catch((res) => toast.error(res.payload));
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Security Password</h4>
          </div>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary mx-3"
              onClick={() => navigate("/security")}
            >
              Back
            </button>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            <form className={`d-flex flex-column my-4`}>
              <FormInput
                label="Current Security Password"
                dom={{
                  type: visible ? "text" : "password",
                  name: "password",
                  id: "passInput",
                  onChange: (e) => setPassword(e.target.value),
                  value: password,
                  placeholder: "Password",
                  disabled: true,
                }}
                containerclass="w-15"
                fullwidth={true}
                postFix={() =>
                  visible ? (
                    <AiFillEye
                      onClick={() => setVisible(false)}
                      className={`${styles.icon} ${styles.absIcon}`}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      onClick={() => setVisible(true)}
                      className={`${styles.icon} ${styles.absIcon}`}
                    />
                  )
                }
              />
              <div className={`d-flex flex-column w-250`}>
                <button className="btn btn-primary mt-3" onClick={regenerateId}>
                  Regenerate
                </button>
                <button className="btn btn-primary mt-3" onClick={copyText}>
                  Copy
                </button>
                <button className="btn btn-primary mt-3" onClick={sendEmail}>
                  Send By Email
                </button>
              </div>
              <div className="my-4">
                <h5 className="mt-4">
                  Please note we only communicate on the site owner's officially
                  listed phone number and email id
                </h5>
                <h5 className="mt-4">
                  Maintaining the safety and security of your security password
                  is your responsibility
                </h5>
                <h5 className="mt-4">
                  If you are unable to sign in via the your phone number and
                  email id and your security password please contact the
                  technology team from the security password sign in page
                </h5>
                <h5 className="mt-4">
                  To regenerate and restore a security password a us $99 service
                  charge is applicable
                </h5>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SecurityPassword;
