import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "./features/admin/adminSlice";
import allJobSlice from "./features/allJob/allJobSlice";
import jobSlice from "./features/job/jobSlice";
import dialogSlice from "./features/dialog/dialogSlice";
import dialogSlice1 from "./features/dialog/dialogSlice1";

const store = configureStore({
  reducer: {
    admin: adminSlice,
    job: jobSlice,
    allJob: allJobSlice,
    dialog: dialogSlice,
    dialog1: dialogSlice1,
  },
});

export default store;
