import React, { useEffect, useState } from "react";
import styles from "./addPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { openDialog } from "../../features/dialog/dialogSlice";
import {
  httpDeletePageData,
  httpUpdatePageData,
} from "../../features/pageData/pageDataSlice";
import { toast } from "react-toastify";
import { httpGetSections } from "../../features/sections/sectionsSlice";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { allLinksSubject, sidebarModules } from "../../utils/globalVars";
import {
  httpGetAllLinks,
  httpGetHomePageData,
} from "../../features/links/linksSlice";

function AddPageContainer() {
  const { pageName, pageId } = useParams();
  const [addSectionData, setAddSectionData] = useState([]);
  const [sections, setSections] = useState([]);
  const [pageData, setPageData] = useState({});
  const [isIconDisable, setIsIconDisable] = useState(true);
  const [isUpIconDisable, setIsUpIconDisable] = useState(true);
  const [isDownIconDisable, setIsDownIconDisable] = useState(true);
  const [isheaderSel, setIsheaderSel] = useState(true);
  const [selSecId, setSelSecId] = useState(null);
  const [isHomePage, setIsHomePage] = useState(true);
  const [linksArr, setLinksArr] = useState([]);
  const [iframeLoading, setIframeLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    allLinksSubject.subscribe(() => getLinks());
    getLinks();
  }, []);

  useEffect(() => {
    dispatch(httpGetSections())
      .then((res) => {
        const secTemp = res.payload?.template?.sections
          ? res.payload.template.sections
          : [];
        setSections(secTemp);
      })
      .catch((res) => toast.error(res.payload));

    pageName === "home" ? setIsHomePage(true) : setIsHomePage(false);
    pageData?._id === pageId ? setIframeLoading(false) : setIframeLoading(true);
  }, [pageName, pageId]);

  useEffect(() => {
    pageData?._id === pageId ? setIframeLoading(false) : setIframeLoading(true);
  }, [pageData]);

  useEffect(() => {
    window.addEventListener("message", iframeMsgHandler);
    window.addEventListener("click", function (e) {
      if (!document.getElementById("iframeContainer")?.contains(e.target)) {
        iframePostMessage("INCRED_SELECT_SECTION_NULL");
      }
    });
    return () => {
      window.removeEventListener("message", iframeMsgHandler);
    };
  }, [addSectionData, linksArr]);

  const iframePostMessage = (action, data) => {
    let iframe = document?.getElementById("iframeId");
    return iframe?.contentWindow.postMessage(
      JSON.stringify({
        action: action,
        data: data,
      }),
      "*"
    );
  };

  const updatePageData = (data) => {
    dispatch(httpUpdatePageData(data))
      .then((res) => {
        toast.success(res.payload);
        iframePostMessage("INCRED_GET_PAGE_DATA");
      })
      .catch((res) => toast.error(res.payload));
  };

  // to pass event into iframe
  useEffect(() => {
    iframePostMessage("INCRED_ADD_SECTIONS", addSectionData);
  }, [addSectionData]);

  // to get events from iframe
  const iframeMsgHandler = (props) => {
    if (typeof props.data === "string") {
      try {
        const parseData = JSON.parse(props.data);
        if (parseData.action === "INCRED_SELECT_SECTION") {
          setSelSecId(parseData.id);
          if (parseData.id) {
            setIsIconDisable(false);
            setIsUpIconDisable(true);
            setIsDownIconDisable(true);
            setIsheaderSel(true);
            const ind = addSectionData.findIndex(
              (d) => d?._id === parseData.id
            );

            if (ind > 0 && ind < addSectionData.length) {
              setIsUpIconDisable(false);
            }
            if (ind > -1 && ind < addSectionData.length - 1) {
              setIsDownIconDisable(false);
            }
            if (
              addSectionData[ind]?.sectionName !== "Section1" &&
              addSectionData[ind]?.sectionId !== 1
            ) {
              setIsheaderSel(false);
            }
          } else {
            setIsIconDisable(true);
          }
        }
        if (parseData.action === "INCRED_EDIT_SECTION") {
          if (
            parseData?.data?.isNotHomePage &&
            parseData?.data?.name === "Header"
          ) {
            dispatch(
              openDialog({
                body: `Sorry this field is only editable from the Home Page`,
                data: {
                  footer: true,
                  confModal: true,
                  title: "Edit Header",
                  buttonText: {
                    cancel: "Close",
                  },
                  cancelBtn: true,
                },
              })
            );
          } else {
            dispatch(
              openDialog({
                data: {
                  footer: true,
                  cancelBtn: true,
                  title: `Edit ${parseData.data.name}`,
                  id: parseData.data.id,
                  buttonText: {
                    submit: "Save",
                  },
                  sections: addSectionData,
                  linksArr: linksArr,
                  isNotHomePage: parseData?.data?.isNotHomePage,
                  onSubmit: async (e, closeModal, modalData) => {
                    closeModal();
                    const tempArr = [...addSectionData];
                    const ind = tempArr.findIndex(
                      (d) => d?._id === parseData.data.id
                    );
                    if (ind > -1) {
                      tempArr.splice(ind, 1, modalData.data);
                      const data = {
                        ...pageData,
                        layoutObj: [{ sections: tempArr }],
                      };
                      setAddSectionData([...tempArr]);
                      updatePageData(data);
                    }
                  },
                },
                path: `forms/Edit${parseData.data.name}`,
              })
            );
          }
        }
        if (parseData.action === "INCRED_SET_PAGE_DATA") {
          setPageData(parseData.data);
          setAddSectionData(
            parseData.data?.layoutObj?.[0]?.sections
              ? parseData.data.layoutObj[0].sections
              : []
          );
        }
        if (parseData.action === "INCRED_CHECK_CMS") {
          iframePostMessage("INCRED_RESPOND_CMS");
        }
      } catch (e) {}
    }
  };

  const getLinks = () => {
    let tempArr = [];
    dispatch(httpGetHomePageData())
      .then((res2) => {
        const homeArrData = res2?.payload?.homePage?.layoutObj?.[0]?.sections;
        dispatch(httpGetAllLinks())
          .then((res) => {
            const response = res?.payload?.links;
            const pages = response?.pages;

            if (pages.length > 0) {
              const tempPg = pages.map((pg, i) => {
                const lbl = (
                  <div className="d-flex align-items-center">
                    <img
                      src={pg?.previewUrl}
                      alt=""
                      width={50}
                      className={styles.HeaderPopoverImg}
                    />
                    <p style={{ textTransform: "capitalize", marginBottom: 0 }}>
                      {i + 1} - {pg?.pageName}
                    </p>
                  </div>
                );
                return {
                  label: lbl,
                  value: pg?.pageName.replace(/\s+/g, "-").toLowerCase(),
                };
              });
              tempArr = [
                ...tempArr,
                { label: "Pages", value: `Pages`, isDisabled: true },
                ...tempPg,
              ];
            }
            if (homeArrData.length > 0) {
              const tempSc = homeArrData.map((sc) => {
                const lbl = (
                  <div className="d-flex">
                    <img
                      src={sc?.previewUrl}
                      alt="preview"
                      width={50}
                      className={styles.HeaderPopoverImg}
                    />
                    <p>
                      {sc?.sectionId} - {sc?.sectionName}
                    </p>
                  </div>
                );
                return { label: lbl, value: `#${sc?._id}` };
              });
              tempArr = [
                ...tempArr,
                { label: "Sections", value: `Sections`, isDisabled: true },
                ...tempSc,
              ];
            }

            setLinksArr(tempArr);
          })
          .catch((res) => toast.error(res.payload));
      })
      .catch((res) => toast.error(res.payload));
  };

  const handleAddItem = () => {
    dispatch(
      openDialog({
        data: {
          footer: true,
          cancelBtn: true,
          title: "Sections List",
          titleClass: "fs20",
          className: "section-list",
          sections: sections,
          addSectionData: addSectionData,
          buttonText: {
            submit: "Add",
          },
          onSubmit: async (e, closeModal, modalData) => {
            closeModal();
            const tempArr = modalData?.data?.length
              ? modalData.data.map((dt) => {
                  return { ...dt, _id: uuid().slice(0, 24) };
                })
              : [];

            const selIndex = addSectionData?.findIndex(
              (dt) => dt._id === selSecId
            );
            let temp = [...addSectionData];
            if (selIndex > -1) {
              temp.splice(selIndex + 1, 0, ...tempArr);
            } else {
              temp = [...temp, ...tempArr];
            }

            const combArr = [...temp];
            const headerInd = combArr.findIndex((d) => d.sectionId === 1);
            const arrData =
              headerInd > -1 ? [combArr[headerInd], ...combArr] : [...combArr];
            headerInd > -1 && arrData.splice(headerInd + 1, 1);
            setAddSectionData(arrData);

            const data = {
              ...pageData,
              layoutObj: [{ sections: [...arrData] }],
            };
            updatePageData(data);
          },
        },
        path: "AddFrameItem",
      })
    );
  };

  const handleUp = () => {
    if (addSectionData.length < 2) {
      toast.error("There should be more than one section");
    } else {
      if (selSecId) {
        const tempArr = [...addSectionData];
        const ind = addSectionData.findIndex((d) => d?._id === selSecId);
        const upItem = tempArr[ind - 1];
        tempArr.splice(ind - 1, 1, tempArr[ind]);
        tempArr.splice(ind, 1, upItem);

        const headerInd = tempArr.findIndex((d) => d.sectionId === 1);
        const arrData =
          headerInd > -1 ? [tempArr[headerInd], ...tempArr] : [...tempArr];
        headerInd > -1 && arrData.splice(headerInd + 1, 1);
        setAddSectionData(arrData);

        const data = {
          ...pageData,
          layoutObj: [{ sections: arrData }],
        };
        updatePageData(data);
      }
      // iframePostMessage("INCRED_SELECT_SECTION_NULL");
      // setSelSecId(null);
      // setIsIconDisable(true);
    }
  };

  const handleDown = () => {
    if (addSectionData.length < 2) {
      toast.error("There should be more than one section");
    } else {
      if (selSecId) {
        const tempArr = [...addSectionData];
        const ind = addSectionData.findIndex((d) => d?._id === selSecId);
        const downItem = tempArr[ind + 1];
        tempArr.splice(ind + 1, 1, tempArr[ind]);
        tempArr.splice(ind, 1, downItem);

        const headerInd = tempArr.findIndex((d) => d.sectionId === 1);
        const arrData =
          headerInd > -1 ? [tempArr[headerInd], ...tempArr] : [...tempArr];
        headerInd > -1 && arrData.splice(headerInd + 1, 1);
        setAddSectionData(arrData);

        const data = {
          ...pageData,
          layoutObj: [{ sections: arrData }],
        };
        updatePageData(data);
      }
      // iframePostMessage("INCRED_SELECT_SECTION_NULL");
      // setSelSecId(null);
      // setIsIconDisable(true);
    }
  };

  const handleCopy = () => {
    if (selSecId) {
      const tempArr = [...addSectionData];
      const ind = addSectionData.findIndex((d) => d?._id === selSecId);
      tempArr.splice(ind + 1, 0, {
        ...tempArr[ind],
        _id: uuid().slice(0, 24),
      });

      const headerInd = tempArr.findIndex((d) => d.sectionId === 1);
      const arrData =
        headerInd > -1 ? [tempArr[headerInd], ...tempArr] : [...tempArr];
      headerInd > -1 && arrData.splice(headerInd + 1, 1);
      setAddSectionData(arrData);

      const data = {
        ...pageData,
        layoutObj: [{ sections: arrData }],
      };
      updatePageData(data);
    }
    iframePostMessage("INCRED_SELECT_SECTION_NULL");
    setSelSecId(null);
    setIsIconDisable(true);
  };

  const handleDelete = () => {
    if (selSecId) {
      dispatch(
        openDialog({
          body: `Do you really want to delete this section?`,
          data: {
            closeButton: false,
            footer: true,
            confModal: true,
            title: "Delete Section",
            buttonText: {
              cancel: "No",
              submit: "Yes",
            },
            cancelBtn: true,
            onSubmit: (e, closeModal) => {
              const tempArr = [...addSectionData];
              const ind = addSectionData.findIndex((d) => d?._id === selSecId);
              tempArr.splice(ind, 1);

              const headerInd = tempArr.findIndex((d) => d.sectionId === 1);
              const arrData =
                headerInd > -1
                  ? [tempArr[headerInd], ...tempArr]
                  : [...tempArr];
              headerInd > -1 && arrData.splice(headerInd + 1, 1);
              setAddSectionData(arrData);

              iframePostMessage("INCRED_SELECT_SECTION_NULL");
              setSelSecId(null);
              setIsIconDisable(true);
              const data = {
                ...pageData,
                layoutObj: [{ sections: arrData }],
              };
              updatePageData(data);
              closeModal();
            },
          },
        })
      );
    }
  };

  const handleMetaModal = () => {
    dispatch(
      openDialog({
        data: {
          footer: true,
          cancelBtn: true,
          title: "Meta Data - SEO Optimization",
          titleClass: "fs20",
          pageData: pageData,
          buttonText: {
            submit: "Save",
          },
          onSubmit: async (e, closeModal, modalData) => {
            if (Object.keys(modalData.data).length) {
              const data = {
                ...modalData.data,
                layoutObj: [{ sections: addSectionData }],
              };
              updatePageData(data);
            }
            closeModal();
          },
        },
        path: "PageDataModal",
      })
    );
  };

  const handleDeleteModal = () => {
    if (pageId) {
      dispatch(
        openDialog({
          body: `Do you really want to delete this page?`,
          data: {
            closeButton: false,
            footer: true,
            confModal: true,
            title: "Delete Page",
            buttonText: {
              cancel: "No",
              submit: "Yes",
            },
            cancelBtn: true,
            onSubmit: (e, closeModal) => {
              dispatch(httpDeletePageData(pageId))
                .then((res) => {
                  toast.success(res.payload);
                  navigate(-1, { replace: true });
                  sidebarModules.next();
                })
                .catch((res) => toast.error(res.payload));
              closeModal();
            },
          },
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">{pageName} Page</h4>
          </div>
          <div className="d-flex align-items-center">
            <div>
              {isHomePage ? null : (
                <button
                  className="btn btn-danger ml-4"
                  onClick={handleDeleteModal}
                  disabled={!pageId || iframeLoading}
                >
                  Delete Page
                </button>
              )}
              <button
                className="btn btn-primary ml-4"
                onClick={handleMetaModal}
                disabled={!pageId || iframeLoading}
              >
                Meta Data
              </button>
              <a
                href={
                  pageName === "home"
                    ? `https://incred.golf/`
                    : `https://incred.golf/${pageName}`
                }
                target="_blank"
                className="btn btn-success text-center ml-4"
                rel="noreferrer"
              >
                Preview
              </a>
            </div>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100">
            <div className={styles.iframeContainer} id="iframeContainer">
              <iframe
                src={
                  pageName === "home"
                    ? `https://incred.golf/`
                    : `https://incred.golf/${pageName}`
                }
                key={pageName}
                title="Frame Load"
                id="iframeId"
              ></iframe>
              <div className={styles.buttonWrapper}>
                <FontAwesomeIcon
                  icon="fa-solid fa-chevron-up"
                  color={
                    isIconDisable || isUpIconDisable || isheaderSel
                      ? "#7b819066"
                      : "#7b8190"
                  }
                  className={
                    isIconDisable || isUpIconDisable || isheaderSel
                      ? "pointer-none"
                      : "pointer-auto"
                  }
                  onClick={handleUp}
                />
                <FontAwesomeIcon
                  icon="fa-solid fa-chevron-down"
                  color={
                    isIconDisable || isDownIconDisable || isheaderSel
                      ? "#7b819066"
                      : "#7b8190"
                  }
                  className={
                    isIconDisable || isDownIconDisable || isheaderSel
                      ? "pointer-none"
                      : "pointer-auto"
                  }
                  onClick={handleDown}
                />
                <FontAwesomeIcon
                  icon="fa-solid fa-copy"
                  color={isIconDisable || isheaderSel ? "#7b819066" : "#7b8190"}
                  className={
                    isIconDisable || isheaderSel
                      ? "pointer-none"
                      : "pointer-auto"
                  }
                  onClick={handleCopy}
                />
                <FontAwesomeIcon
                  icon="fa-solid fa-plus"
                  color={"#7b8190"}
                  onClick={handleAddItem}
                />
                <FontAwesomeIcon
                  icon="fa-solid fa-trash"
                  color={isIconDisable ? "#7b819066" : "#7b8190"}
                  className={isIconDisable ? "pointer-none" : "pointer-auto"}
                  onClick={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddPageContainer;
