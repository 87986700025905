import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllProductTypeThunk = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/product/productType`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const addProductTypeThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/product/productType", data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateProductTypeThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(
      `/product/productType/${data?._id}`,
      data
    );
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteProductTypeThunk = async (productId, thunkAPI) => {
  try {
    const response = await IncredAPI.delete(
      `/product/productType/${productId}`
    );
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
