import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const verifyPasswordThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/verifyPass", data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const getOTPThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/generateOTP", data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const verifyOTPThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post(
      "/verifyOTP",
      data.mode === "email"
        ? {
            otpId: data.otpId,
            otpCodeEmail: data.otpCodeEmail,
          }
        : { otpId: data.otpId, otpCodeMobile: data.otpCodeMobile }
    );
    return { ...response.data, mode: data.mode };
  } catch (error) {
    return thunkAPI.rejectWithValue({
      msg: error.response.data.msg,
      mode: data.mode,
    });
  }
};

export const updateProfileThunk = async ({ userId, profileData }, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/user/${userId}`, profileData);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getCountryCodeThunk = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/countryCode`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
