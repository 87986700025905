import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllAdminThunk,
  addAdminThunk,
  updateAdminThunk,
  deleteAdminThunk,
  updatePasswordThunk,
} from "./securityThunk";

const initialState = {
  isLoading: false,
  admins: null,
};

export const updatePassword = createAsyncThunk(
  "security/updatePassword",
  updatePasswordThunk
);

export const httpGetAllAdmin = createAsyncThunk(
  "security/allAdmin",
  getAllAdminThunk
);

export const httpAddAdmin = createAsyncThunk(
  "security/addAdmin",
  addAdminThunk
);

export const httpUpdateAdmin = createAsyncThunk(
  "security/updateAdmin",
  updateAdminThunk
);

export const httpDeleteAdmin = createAsyncThunk(
  "security/deleteAdmin",
  deleteAdminThunk
);

const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllAdmin.pending]: (state) => {
      console.log("httpGetAllAdmin.pending");
      state.isLoading = true;
    },
    [httpGetAllAdmin.fulfilled]: (state, { payload }) => {
      console.log("httpGetAllAdmin payload", payload);
      state.isLoading = false;
      state.admins = payload.admins;
    },
    [httpGetAllAdmin.rejected]: (state, { payload }) => {
      console.log("httpGetAllAdmin.rejected", payload);
      state.isLoading = false;
      toast.error(payload);
    },
    [httpAddAdmin.pending]: (state) => {
      console.log("httpAddAdmin.pending");
      state.isLoading = true;
    },
    [httpAddAdmin.fulfilled]: (state, { payload }) => {
      console.log("httpAddAdmin payload", payload);
      state.isLoading = false;
      toast.success(payload);
    },
    [httpAddAdmin.rejected]: (state, { payload }) => {
      console.log("httpAddAdmin.rejected", payload);
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateAdmin.pending]: (state) => {
      console.log("httpUpdateAdmin.pending");
      state.isLoading = true;
    },
    [httpUpdateAdmin.fulfilled]: (state, { payload }) => {
      console.log("httpUpdateAdmin payload", payload);
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateAdmin.rejected]: (state, { payload }) => {
      console.log("httpUpdateAdmin.rejected", payload);
      state.isLoading = false;
      toast.error(payload);
    },
    [httpDeleteAdmin.fulfilled]: (state, { payload }) => {
      console.log("httpDeleteAdmin payload", payload);
      toast.success(payload);
    },
    [httpDeleteAdmin.rejected]: (state, { payload }) => {
      console.log("httpDeleteAdmin.rejected", payload);
      toast.error(payload);
    },
    [updatePassword.pending]: (state) => {
      console.log("updatePassword.pending");
      state.isLoading = true;
    },
    [updatePassword.fulfilled]: (state, { payload }) => {
      console.log("updatePassword payload", payload);
      state.isLoading = false;
      toast.error(payload.msg);
    },
    [updatePassword.rejected]: (state, { payload }) => {
      console.log("updatePassword.rejected", payload);
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = securitySlice.actions;
export default securitySlice.reducer;
