import React, { useEffect, useState } from "react";
import styles from "./security.module.css";
import { AiFillDelete } from "react-icons/ai";
import { MdSave } from "react-icons/md";
import FormInput from "../../components/form/FormInput";
import PhoneInput from "../../components/form/PhoneInput";
import { Link } from "react-router-dom";
import { /*useSelector*/ useDispatch } from "react-redux";
import {
  httpGetAllAdmin,
  httpAddAdmin,
  httpUpdateAdmin,
  httpDeleteAdmin,
} from "../../features/security/securitySlice";
import { httpGetCountryCode } from "../../features/admin/adminSlice";
import { toast } from "react-toastify";
import { openDialog } from "../../features/dialog/dialogSlice";
import Tooltip from "rc-tooltip";

function SecurityContainer() {
  const dispatch = useDispatch();
  // const { isLoading, admins } = useSelector((store) => {
  //   return store.security;
  // });
  const [codesArr, setCodesArr] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllAdminData();
    dispatch(httpGetCountryCode())
      .then((res) => setCodesArr(res.payload.countryCodes))
      .catch((res) => toast.error(res.payload));
  }, []);

  const getAllAdminData = () => {
    setIsLoading(true);
    dispatch(httpGetAllAdmin())
      .then((res) => {
        const adminTemp = res.payload.admins;
        const adminArr = adminTemp.filter((data) => data.isOwner);
        const userArr = adminTemp.filter((data) => !data.isOwner);
        setAdminData(adminArr);
        setUserData(userArr);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res.payload);
        setIsLoading(false);
      });
  };

  const handleDelete = (i, role) => {
    if (role === "admin") {
      if (adminData[i]._id) {
        dispatch(
          openDialog({
            body: `Do you really want to delete this user?`,
            data: {
              closeButton: false,
              footer: true,
              confModal: true,
              title: "Delete User",
              buttonText: {
                cancel: "No",
                submit: "Yes",
              },
              cancelBtn: true,
              onSubmit: (e, closeModal) => {
                const deleteId = adminData[i]._id;
                dispatch(httpDeleteAdmin(deleteId))
                  .then((res) => {
                    toast.success(res.payload);
                    getAllAdminData();
                  })
                  .catch((res) => toast.error(res.payload));
                closeModal();
              },
            },
          })
        );
      } else {
        const tempArr = [...adminData];
        tempArr.splice(i, 1);
        setAdminData(tempArr);
      }
    } else if (role === "user") {
      if (userData[i]._id) {
        dispatch(
          openDialog({
            body: `Do you really want to delete this user?`,
            data: {
              closeButton: false,
              footer: true,
              confModal: true,
              title: "Delete User",
              buttonText: {
                cancel: "No",
                submit: "Yes",
              },
              cancelBtn: true,
              onSubmit: (e, closeModal) => {
                const deleteId = userData[i]._id;
                dispatch(httpDeleteAdmin(deleteId))
                  .then((res) => {
                    toast.success(res.payload);
                    getAllAdminData();
                  })
                  .catch((res) => toast.error(res.payload));
                closeModal();
              },
            },
          })
        );
      } else {
        const tempArr = [...userData];
        tempArr.splice(i, 1);
        setUserData(tempArr);
      }
    }
  };

  const addUpdateCall = (type, data) => {
    if (type === "update") {
      dispatch(httpUpdateAdmin(data))
        .then((res) => {
          toast.success(res.payload);
          getAllAdminData();
        })
        .catch((res) => toast.error(res.payload));
    } else if (type === "add") {
      dispatch(httpAddAdmin(data))
        .then((res) => {
          toast.success(res.payload);
          getAllAdminData();
        })
        .catch((res) => toast.error(res.payload));
    }
  };

  const handleSave = (i, role) => {
    let data = {};
    if (role === "admin") {
      data = { ...adminData[i], isOwner: true };
      if (adminData[i]._id) {
        addUpdateCall("update", data);
      } else {
        addUpdateCall("add", data);
      }
    } else if (role === "user") {
      data = { ...userData[i], isOwner: false };
      if (userData[i]._id) {
        addUpdateCall("update", data);
      } else {
        addUpdateCall("add", data);
      }
    }
  };

  const inputChange = (e, i, role) => {
    const value = e.target.value;
    if (role === "admin") {
      const tempArr = [...adminData];
      tempArr[i].countryCode = value;
      setAdminData(tempArr);
    } else {
      const tempArr = [...userData];
      tempArr[i].countryCode = value;
      setUserData(tempArr);
    }
  };

  const handleChange = (e, i, role) => {
    const name = e.target.name;
    const value = e.target.value;
    if (role === "admin") {
      const tempArr = [...adminData];
      tempArr[i][name] = value;
      setAdminData(tempArr);
    } else {
      const tempArr = [...userData];
      tempArr[i][name] = value;
      setUserData(tempArr);
    }
  };

  const handleAddMore = (role) => {
    const tempArr = role === "admin" ? [...adminData] : [...userData];
    tempArr.push({
      name: "",
      countryCode: "+91",
      mobile: "",
      email: "",
    });
    role === "admin" ? setAdminData(tempArr) : setUserData(tempArr);
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column  overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Security / Access Management</h4>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <Link to="/security-password">
                <button className="btn btn-primary mx-3">
                  Security Password
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            <div className={`d-flex flex-column my-4`}>
              <div>
                <div className="d-flex justify-content-between">
                  <h4 className="page-title">&nbsp;</h4>
                  <button
                    type="button"
                    className={`btn nowrap login-btn-width mx-3 btn-primary`}
                    onClick={() => handleAddMore("admin")}
                  >
                    Add More
                  </button>
                </div>
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : adminData && adminData?.length ? (
                  adminData?.map((dt, i) => (
                    <div className={`${styles.all__jobs} mt-4`} key={i}>
                      <FormInput
                        label="Site Owner Name"
                        dom={{
                          type: "text",
                          name: "name",
                          onChange: (e) => handleChange(e, i, "admin"),
                          value: dt.name,
                          placeholder: "Name",
                        }}
                        fullwidth={true}
                      />
                      <PhoneInput
                        label="Official Phone Number"
                        dom={{
                          name: "mobile",
                          value: dt.mobile,
                          onChange: (e) => handleChange(e, i, "admin"),
                          placeholder: "Phone No.",
                        }}
                        countryCode={dt.countryCode}
                        options={codesArr}
                        inputChange={(e) => inputChange(e, i, "admin")}
                        fullwidth={true}
                      />
                      <FormInput
                        label="Official Email ID"
                        dom={{
                          type: "email",
                          name: "email",
                          value: dt.email,
                          onChange: (e) => handleChange(e, i, "admin"),
                          placeholder: "Email",
                        }}
                        fullwidth={true}
                        extraContent={() => (
                          <>
                            <Tooltip placement="bottom" overlay={"Delete"}>
                              <AiFillDelete
                                onClick={() => handleDelete(i, "admin")}
                                className={`${styles.icon} ml-3`}
                              />
                            </Tooltip>
                            <Tooltip placement="bottom" overlay={"Save"}>
                              <MdSave
                                onClick={() => handleSave(i, "admin")}
                                className={`${styles.icon} ml-3`}
                              />
                            </Tooltip>
                          </>
                        )}
                      />
                    </div>
                  ))
                ) : null}
              </div>
              <div>
                <div className="d-flex justify-content-between mt-4">
                  <h4 className="page-title">Additional Access</h4>
                  <button
                    type="button"
                    className={`btn nowrap login-btn-width mx-3 btn-primary`}
                    onClick={() => handleAddMore("user")}
                  >
                    Add More
                  </button>
                </div>
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : userData && userData?.length ? (
                  userData?.map((rd, i) => (
                    <div className={`${styles.all__jobs} mt-4`} key={i}>
                      <FormInput
                        label="Name"
                        dom={{
                          type: "text",
                          name: "name",
                          value: rd.name,
                          onChange: (e) => handleChange(e, i, "user"),
                          placeholder: "Name",
                        }}
                        fullwidth={true}
                      />
                      <PhoneInput
                        label="Phone Number"
                        dom={{
                          name: "mobile",
                          value: rd.mobile,
                          onChange: (e) => handleChange(e, i, "user"),
                          placeholder: "Phone No.",
                        }}
                        countryCode={rd.countryCode}
                        options={codesArr}
                        inputChange={(e) => inputChange(e, i, "user")}
                        fullwidth={true}
                      />
                      <FormInput
                        label="Email ID"
                        dom={{
                          type: "email",
                          name: "email",
                          value: rd.email,
                          onChange: (e) => handleChange(e, i, "user"),
                          placeholder: "Email",
                        }}
                        fullwidth={true}
                        extraContent={() => (
                          <>
                            <Tooltip placement="bottom" overlay={"Delete"}>
                              <AiFillDelete
                                onClick={() => handleDelete(i, "user")}
                                className={`${styles.icon} ml-3`}
                              />
                            </Tooltip>
                            <Tooltip placement="bottom" overlay={"Save"}>
                              <MdSave
                                onClick={() => handleSave(i, "user")}
                                className={`${styles.icon} ml-3`}
                              />
                            </Tooltip>
                          </>
                        )}
                      />
                    </div>
                  ))
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SecurityContainer;
