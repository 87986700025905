import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";
// import { showLoading, hideLoading } from "./securitySlice";

export const updatePasswordThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/updatePass`, data);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getAllAdminThunk = async (_, thunkAPI) => {
  try {
    const response = await IncredAPI.get("/admin");
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const addAdminThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/admin", data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateAdminThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/admin/${data._id}`, data);
    // response.data.updatedAdmin;
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteAdminThunk = async (adminId, thunkAPI) => {
  try {
    // thunkAPI.dispatch(showLoading());
    const response = await IncredAPI.delete(`/admin/${adminId}`);
    // thunkAPI.dispatch(hideLoading());
    return response.data.msg;
  } catch (error) {
    // thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
