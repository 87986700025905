import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Outlet } from "react-router-dom";
import { sidebarCollpse } from "../../utils/globalVars";
import { Sidebar } from "./../../components";
import { useNavigate } from "react-router-dom";

const SharedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { admin } = useSelector((store) => {
    return store.admin;
  });
  useEffect(() => {
    if (location?.pathname == "/") {
      const modules = admin ? JSON.parse(JSON.stringify(admin.modules)) : [];
      const homeData = modules.find((data) => data.path === "/home");

      homeData
        ? navigate(`${homeData?.path}/${homeData?.pageId}`)
        : navigate("/");
    }
    sidebarCollpse.subscribe(() => changeCollpse());
  }, []);

  const changeCollpse = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <div>
      {/* <Header /> */}
      <Sidebar pathname={pathname} isCollapsed={isCollapsed} />
      <div id={!isCollapsed ? "content" : "content-collapse"}>
        <div className="wrapper h-100 w-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
