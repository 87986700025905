import React from "react";

function AnalyticsContainer() {
  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column  overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h3 className="page-title mb-0">Analytics</h3>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            <div className={`d-flex flex-column my-4`}>
              <div>
                <div className="d-flex justify-content-between">
                  <h3 className="page-title">Analytics div</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AnalyticsContainer;
