import React, { useCallback, useEffect, useState } from "react";
import styles from "./orders.module.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HiOutlineDownload } from "react-icons/hi";
import {
  httpGetAllOrders,
  httpUpdateOrder,
} from "../../features/orders/ordersSlice";
import { toast } from "react-toastify";
import { BiSearch } from "react-icons/bi";
import Pagination from "../../components/pagination/pagination";
import { debounce } from "debounce";
import { FormInput, FormSelect } from "../../components";
import moment from "moment";
import FormDatePicker from "../../components/form/FormDatePicker";
import { Spinner } from "reactstrap";
import { exportToCsv } from "../../utils/globalVars";

const statusOptions = [
  {
    label: "new order - pay later",
    value: 1,
  },
  {
    label: "new order - stripe paid",
    value: 2,
  },
  {
    label: "processing",
    value: 3,
  },
  {
    label: "shipped",
    value: 4,
  },
  {
    label: "in conversation",
    value: 5,
  },
  {
    label: "refunded",
    value: 6,
  },
];

const keymapperTransacation = {
  header: [
    "Order Id",
    "Customer Name",
    "EmailId",
    "Phone No.",
    "Address",
    "Note",
    "Order Date",
    "Total Items",
    "Status",
  ],
  keys: [
    "orderId",
    "name",
    "emailId",
    "phoneNumber",
    ["street", "city", "state", "country", "areaCode"],
    "note",
    "orderDate",
    "totalItems",
    "orderStatus",
  ],
  mapper: {
    orderStatus: {
      1: "new order - pay later",
      2: "new order - stripe paid",
      3: "processing",
      4: "shipped",
      5: "in conversation",
      6: "refunded",
    },
  },
};

function OrdersContainer() {
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(undefined);
  const [sort, setSort] = useState(1);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().add(2, "days").format("YYYY-MM-DD")
  );

  useEffect(() => {
    getAllOrdersData(search);
  }, [page, sort, startDate, endDate]);

  useEffect(() => {
    if (search?.length === 0 || search?.length > 3) getSearchDebounce(search);
  }, [search]);

  const getSearchDebounce = useCallback(
    debounce((search) => {
      getAllOrdersData(search);
    }, 1000),
    []
  );

  const getAllOrdersData = (searchVal) => {
    setIsLoading(true);
    const query = `?page=${page}&limit=20&searchKeyword=${
      searchVal || ""
    }&sortBy=${sort}&fromDate=${moment(startDate).format("X")}&endDate=${moment(
      endDate
    ).format("X")}`;
    dispatch(httpGetAllOrders({ query }))
      .then((res) => {
        const ordersArr = res?.payload?.orders;
        setOrderData(ordersArr);
        setTotalCount(res?.payload?.totalRec);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res?.payload);
        setIsLoading(false);
      });
  };

  const handleSelectChange = (e, i) => {
    const temp = [...orderData];

    if (e) temp[i].orderStatus = e.value;
    setOrderData(temp);

    dispatch(httpUpdateOrder(temp[i]))
      .then((res) => {
        toast.success(res?.payload);
        getAllOrdersData();
      })
      .catch((res) => toast.error(res?.payload));
  };

  const handleDownload = () => {
    const arr =
      orderData?.length > 0
        ? orderData?.map((dt) => ({
            ...dt,
            ...dt?.customer,
            orderId: dt?._id,
            orderDate: moment.unix(dt?.dateTime).format("DD MMM, YYYY"),
            totalItems: dt?.items?.length || 0,
            orderStatus: dt?.orderStatus || "",
          }))
        : [];
    exportToCsv(
      arr,
      "Orders_Data" + moment().format("YYYY-MM-DD_HH.mm"),
      keymapperTransacation
    );
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column  overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Orders</h4>
          </div>
          <div className="d-flex align-items-center">
            <div className="mx-2">
              <FormInput
                dom={{
                  type: "text",
                  name: "search",
                  onChange: (e) => setSearch(e.target.value),
                  value: search,
                  placeholder: "Search",
                  className: styles.searchInput,
                }}
                fullwidth={true}
                postFix={() => (
                  <BiSearch
                    className={`${styles.icon} ${styles.absIcon} m-0`}
                  />
                )}
              />
            </div>
            <div className="mx-2">
              <FormDatePicker
                name="startDate"
                value={startDate}
                inputChange={(e) => setStartDate(e.target.value)}
                dom={{
                  placeholder: "Start Date",
                  popperPlacement: "top",
                  type: "date",
                  dateFormat: "dd/MM/yyyy",
                }}
              />
            </div>
            <div className="mx-2">
              <FormDatePicker
                name="endDate"
                value={endDate}
                inputChange={(e) => setEndDate(e.target.value)}
                dom={{
                  placeholder: "End Date",
                  popperPlacement: "top",
                  type: "date",
                  dateFormat: "dd/MM/yyyy",
                }}
              />
            </div>
            <div className="mx-2">
              <HiOutlineDownload
                className={`${styles.downloadicon}`}
                onClick={handleDownload}
              />
            </div>
            <div className="mx-2">
              <Link to="/orders-setting">
                <button className="btn btn-primary">Email IDs</button>
              </Link>
            </div>
            <div className="mx-2 mr-0">
              <FormSelect
                name="sort-dropdown"
                className={styles.formSelect}
                onChange={(e) => {
                  if (e) setSort(e?.value);
                }}
                value={{
                  label: sort,
                  value: sort,
                }}
                fullwidth={true}
                options={statusOptions}
                wrapperClass="w-250"
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            {isLoading && (
              <div className="h-100 d-flex align-items-center justify-content-center">
                <Spinner className="m-1" color="info" />
              </div>
            )}
            {!isLoading && orderData && orderData?.length ? (
              <div className={`d-flex flex-column`}>
                {orderData?.map((dt, i) => (
                  <div className={`${styles.orders} mt-4`} key={i}>
                    <div className={"d-flex flex-column"}>
                      <p className={`mb-0 w-600 ${styles.ordersDetail}`}>
                        orders # {dt?._id} -{" "}
                        {moment.unix(dt?.dateTime).format("DD MMM, YYYY")}
                      </p>
                      <p className={`mb-0 w-600`}>{dt?.customer?.name}</p>
                      <div className="d-flex">
                        <p className={`mb-0 w-600 ${styles.ordersDetail}`}>
                          Ph. -
                        </p>
                        <p className={`mb-0 w-600`}>
                          &nbsp;{dt?.customer?.phoneNumber}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className={`mb-0 w-600 ${styles.ordersDetail}`}>
                          E -
                        </p>
                        <p className={`mb-0 w-600`}>
                          &nbsp;{dt?.customer?.emailId}
                        </p>
                      </div>
                    </div>
                    <Link
                      to={`/view-order/${dt._id}`}
                      className={`${styles.viewtxt}`}
                    >
                      <button className={`btn btn-primary mx-3`}>
                        view Order
                      </button>
                    </Link>
                    <div>
                      <FormSelect
                        name="sort-dropdown"
                        className={styles.formSelect}
                        onChange={(e) => handleSelectChange(e, i)}
                        value={{
                          label: dt?.orderStatus,
                          value: dt?.orderStatus,
                        }}
                        fullwidth={true}
                        options={statusOptions}
                        wrapperClass="w-250"
                        isClearable={false}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !isLoading && (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <h3>No Data Found</h3>
                </div>
              )
            )}
          </div>
        </div>
        <div>
          <Pagination
            paging={{
              count: totalCount,
              rows: 20,
              page: page,
            }}
            onPageClick={(page) => {
              setPage(page);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default OrdersContainer;
