import React, { useEffect, useState } from "react";
import styles from "./leads.module.css";
import { AiFillDelete } from "react-icons/ai";
import FormInput from "../../components/form/FormInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  httpGetAllEmails,
  httpUpdateEmails,
} from "../../features/leads/leadsSlice";
import { toast } from "react-toastify";
import Tooltip from "rc-tooltip";

function EmailIdsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailsData, setEmailsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllEmailsData();
  }, []);

  const getAllEmailsData = () => {
    setIsLoading(true);
    dispatch(httpGetAllEmails())
      .then((res) => {
        const emailsArr = res.payload.emailIds?.[0]?.emailIds;
        setEmailsData(emailsArr);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res.payload);
        setIsLoading(false);
      });
  };

  const handleDelete = (i) => {
    const tempArr = [...emailsData];
    tempArr.splice(i, 1);
    setEmailsData(tempArr);
  };

  const handleSave = () => {
    dispatch(httpUpdateEmails({ emailIds: emailsData }))
      .then((res) => {
        toast.success(res.payload);
        getAllEmailsData();
      })
      .catch((res) => toast.error(res.payload));
  };

  const handleChange = (e, i) => {
    const value = e.target.value;
    const tempArr = [...emailsData];
    tempArr[i] = value;
    setEmailsData(tempArr);
  };

  const handleAddMore = () => {
    const tempArr = [...emailsData];
    tempArr.push("");
    setEmailsData(tempArr);
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column  overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Lead Email IDs</h4>
          </div>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary mx-3"
              onClick={() => navigate("/leads")}
            >
              Back
            </button>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            <div className={`d-flex flex-column my-4`}>
              <div>
                <div className="d-flex justify-content-between">
                  <h5 className="">
                    Send Lead Email IDs To The Following Email IDs
                  </h5>
                  <button
                    type="button"
                    className={`btn nowrap login-btn-width mx-3 btn-primary`}
                    onClick={handleAddMore}
                  >
                    Add More
                  </button>
                </div>
                {isLoading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : emailsData && emailsData?.length ? (
                  emailsData?.map((dt, i) => (
                    <div className={`${styles.leads} mt-4`} key={i}>
                      <FormInput
                        dom={{
                          type: "email",
                          name: "email",
                          value: dt,
                          onChange: (e) => handleChange(e, i),
                          placeholder: "Email",
                        }}
                        // fullwidth={true}
                        extraContent={() => (
                          <Tooltip placement="bottom" overlay={"Delete"}>
                            <AiFillDelete
                              onClick={() => handleDelete(i)}
                              className={`${styles.icon} ml-3`}
                            />
                          </Tooltip>
                        )}
                      />
                    </div>
                  ))
                ) : (
                  <p>No Data Found</p>
                )}
                <div className="d-flex justify-content-between">
                  <h5 className="">&nbsp;</h5>
                  <button
                    type="button"
                    className={`btn nowrap login-btn-width mx-3 btn-primary`}
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EmailIdsContainer;
