import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllOrdersThunk,
  updateOrderThunk,
  viewOrderThunk,
} from "./ordersThunk";

const initialState = {
  isLoading: false,
  orders: null,
  orderData: {},
};

export const httpGetAllOrders = createAsyncThunk(
  "orders/allOrders",
  getAllOrdersThunk
);

export const httpUpdateOrder = createAsyncThunk(
  "orders/updateOrder",
  updateOrderThunk
);

export const httpViewOrder = createAsyncThunk(
  "orders/viewOrder",
  viewOrderThunk
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllOrders.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllOrders.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.orders = payload.orders;
    },
    [httpGetAllOrders.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateOrder.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpViewOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [httpViewOrder.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.orderData = payload.order;
    },
    [httpViewOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = ordersSlice.actions;
export default ordersSlice.reducer;
