import React, { useEffect, useState } from "react";
import Select from "react-select";

const FormSelect = (props) => {
  const {
    label = "",
    error = "",
    errorMessage,
    preFix,
    postFix,
    errorClass = "",
    extraContent,
    wrapperClass = "",
    containerclass = "",
    fullwidth = false,
    options,
    name,
    onChange,
    value,
    className,
    isCreatable = false,
    isClearable = true,
    isSearchable = true,
    required = false,
    placeholder = "",
  } = props;

  const [selectValue, setValues] = useState({});

  useEffect(() => {
    const filteredObj = options?.filter((op) => {
      return op?.value === value?.value;
    });

    setValues(filteredObj?.[0] || value);
  }, [options, value]);

  const onInputChange = (value, option) => {
    if (option?.action === "input-change") {
      onChange({
        label: value,
        value,
      });
    }
  };

  return (
    <div className={`${wrapperClass ? wrapperClass : ""}`}>
      {label && (
        <div className="px-0">
          <label>{label}</label>
          {required && <small className="btn-danger ml-1">*</small>}
        </div>
      )}
      <div
        className={`d-flex align-items-center position-relative ${
          containerclass ? containerclass : ""
        }`}
      >
        {preFix && preFix()}
        <Select
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          options={options || []}
          name={name}
          onChange={onChange}
          onInputChange={isCreatable ? onInputChange : () => {}}
          value={selectValue}
          classNamePrefix="custom-select"
          className={`form-control px-0 py-0 ${className} ${
            error ? "form-control-error" : ""
          } ${fullwidth ? "w-100" : ""}`}
        />
        {postFix && postFix()}
        {extraContent && extraContent()}
      </div>
      {error ? (
        <div className="input-error-message">
          <span className={` ${errorClass}`}>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default FormSelect;
