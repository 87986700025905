import { Subject } from "rxjs";

export const sidebarModules = new Subject();

export const allLinksSubject = new Subject();

export const sidebarCollpse = new Subject();

export const allLeadsSubject = new Subject();

export function exportToCsv(rows, filename, keyMapper) {
  if (!rows.length) return;
  // filename = filename ? filename.replace(/[^A-Z0-9]+/ig, "_") : 'excel_list' ;
  filename = filename ? filename.replace(/ /g, "_") : "excel_list";
  keyMapper.mapper = keyMapper.mapper || {};
  var processRow = function (row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvFile = "";
  csvFile += processRow(keyMapper.header);
  for (var i = 0; i < rows.length; i++) {
    let row = [];
    for (let k = 0; k < keyMapper.keys.length; k++) {
      if (Array.isArray(keyMapper.keys[k])) {
        if (keyMapper.keys[k].length) {
          let fvalue = keyMapper.keys[k].map((ik) => {
            return rows[i][ik] ? rows[i][ik] : "";
          });
          row[k] = fvalue.join("-");
        } else {
          row[k] = "--";
        }
      } else {
        row[k] =
          rows[i][keyMapper.keys[k]] || rows[i][keyMapper.keys[k]] === 0
            ? keyMapper.mapper[keyMapper.keys[k]]
              ? keyMapper.mapper[keyMapper.keys[k]][rows[i][keyMapper.keys[k]]]
              : rows[i][keyMapper.keys[k]]
            : "--";
      }
    }
    csvFile += processRow(row);
  }

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename + ".csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
