import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllProductsThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.get(
      `/products${data.query ? data.query : ""}`
    );
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getProductThunk = async (productId, thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/product/${productId}`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const addProductThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/product", data);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI, "addUpdateProd");
  }
};

export const updateProductThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/product/${data?._id}`, data);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI, "addUpdateProd");
  }
};

export const deleteProductThunk = async (productId, thunkAPI) => {
  try {
    const response = await IncredAPI.delete(`/product/${productId}`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
