import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllModulesThunk = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/module`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
