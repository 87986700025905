import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addPageDataThunk,
  getPageDataThunk,
  updatePageDataThunk,
  deletePageDataThunk,
} from "./pageDataThunk";

const initialState = {
  isLoading: false,
  pageData: null,
};

export const httpAddPageData = createAsyncThunk(
  "security/addPageData",
  addPageDataThunk
);

export const httpGetPageData = createAsyncThunk(
  "pageData/getPageData",
  getPageDataThunk
);

export const httpUpdatePageData = createAsyncThunk(
  "pageData/updatePageData",
  updatePageDataThunk
);

export const httpDeletePageData = createAsyncThunk(
  "security/deletePageData",
  deletePageDataThunk
);

const pageDataSlice = createSlice({
  name: "pageData",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpAddPageData.pending]: (state) => {
      state.isLoading = true;
    },
    [httpAddPageData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpAddPageData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpGetPageData.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetPageData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.pageData = payload.layout;
    },
    [httpGetPageData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdatePageData.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdatePageData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdatePageData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpDeletePageData.fulfilled]: (state, { payload }) => {
      toast.success(payload);
    },
    [httpDeletePageData.rejected]: (state, { payload }) => {
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = pageDataSlice.actions;
export default pageDataSlice.reducer;
