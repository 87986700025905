import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllEmailsThunk = async (_, thunkAPI) => {
  try {
    const response = await IncredAPI.get("/leads/emailIds");
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateEmailsThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/leads/emailIds", data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getAllLeadsThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.get(
      `/leads${data.query ? data.query : ""}`
    );
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateLeadThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/leads/${data._id}`, data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
