import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";
// import { showLoading, hideLoading } from "./securitySlice";

export const addPageDataThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/page", data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getPageDataThunk = async (id, thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/page/${id}`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updatePageDataThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/page/${data._id}`, data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deletePageDataThunk = async (id, thunkAPI) => {
  try {
    const response = await IncredAPI.delete(`/page/${id}`);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
