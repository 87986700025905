import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllProductCategoryThunk,
  addProductCategoryThunk,
  updateProductCategoryThunk,
  deleteProductCategoryThunk,
} from "./productCategoryThunk";

const initialState = {
  isLoading: false,
  productCategory: null,
};

export const httpGetAllProductCategory = createAsyncThunk(
  "productCategory/allCategory",
  getAllProductCategoryThunk
);

export const httpAddProductCategory = createAsyncThunk(
  "productCategory/updateCategory",
  addProductCategoryThunk
);

export const httpUpdateProductCategory = createAsyncThunk(
  "productCategory/updateCategory",
  updateProductCategoryThunk
);

export const httpDeleteProductCategory = createAsyncThunk(
  "productCategory/updateCategory",
  deleteProductCategoryThunk
);

const productCategorySlice = createSlice({
  name: "productCategory",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllProductCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllProductCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.emailIds = payload.productCategory;
    },
    [httpGetAllProductCategory.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpAddProductCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [httpAddProductCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpAddProductCategory.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateProductCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateProductCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateProductCategory.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpDeleteProductCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [httpDeleteProductCategory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpDeleteProductCategory.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = productCategorySlice.actions;
export default productCategorySlice.reducer;
