// non-modal popovers
import React, { useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";

function CustomePopover2({
  children,
  content,
  positions,
  align,
  openState,
  setOpenState,
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (setOpenState) {
      setOpenState(isPopoverOpen);
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    setIsPopoverOpen(Boolean(openState));
  }, [openState]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={positions || ["bottom"]}
      align={align || "end"}
      padding={5}
      reposition={false}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={content}
      containerClassName="zIndex1051"
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>{children}</div>
    </Popover>
  );
}

export default CustomePopover2;
