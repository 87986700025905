import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../features/dialog/dialogSlice1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./dialog.module.css";
import { Modal } from "reactstrap";

const CustomDialog1 = (props) => {
  const [modalState, setModel] = useState({ isLoaded: false });
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [modalData, setModalData] = useState({});
  const dispatch = useDispatch();
  const dialog = useSelector((store) => {
    return store.dialog1;
  });

  // useEffect(() => {
  //   Modal.setAppElement("body");
  // }, []);

  useEffect(() => {
    if (dialog.path) {
      import(`./${dialog.path}`).then((module) =>
        setModel({
          module: module.default,
          isLoaded: dialog.state ? true : false,
          body: "",
        })
      );
    } else {
      setIsSubmitDisable(false);
      setModel({
        body: dialog.body,
        module: "",
        isLoaded: dialog.state ? true : false,
      });
    }
  }, [dialog]);

  const onClose = () => {
    dispatch(closeDialog());
    setModalData({});
  };

  const { data = {}, state } = dialog;

  const {
    title,
    footer,
    cancelBtn,
    onSubmit,
    closeButton = true,
    buttonText = {},
    submitArg,
    onCancel,
    disabledBackdrop = false,
    confModal = false,
  } = data;

  let handleCloseBUtton = closeButton;
  const { module: Component, isLoaded, body = "" } = modalState;

  let extraObj = {};
  let modelDynamic = {};
  if (!disabledBackdrop) {
    extraObj["closeButton"] = closeButton;
  } else {
    modelDynamic["onBackdropClick"] = "false";
    modelDynamic["keyboard"] = true;
    handleCloseBUtton = false;
  }

  return (
    <div>
      {isLoaded && (
        <Modal
          isOpen={state}
          toggle={onClose}
          centered={true}
          scrollable={true}
          className={confModal ? "" : styles.modalClass1}
        >
          {state && (
            <>
              {title && (
                <div className="modal-header" style={{ borderBottom: 0 }}>
                  <h5 className="mb-0">{title}</h5>
                  {handleCloseBUtton && (
                    <FontAwesomeIcon
                      icon="fa-solid fa-xmark"
                      className={`${styles.floatRight} cursor-pointer`}
                      onClick={(e) => {
                        onClose();
                        onCancel && onCancel(e, onClose);
                      }}
                      data-dismiss="modal"
                      aria-label="Close"
                    />
                  )}
                </div>
              )}
              <div className={`modal-body ${styles.modalBody}`} id="modal-body">
                {isLoaded && Component && (
                  <Component
                    {...data}
                    key={state}
                    closeModal={onClose}
                    setIsSubmitDisable={setIsSubmitDisable}
                    modalData={modalData}
                    setModalData={setModalData}
                  />
                )}
                {isLoaded && body && body}
              </div>
              {footer && (
                <div
                  className="modal-footer"
                  id="modal-footer"
                  style={{ borderTop: 0 }}
                >
                  {cancelBtn && (
                    <button
                      type="button"
                      color="primary"
                      onClick={(e) => {
                        onClose();
                        onCancel && onCancel(e, onClose);
                      }}
                      className="btn btn btn-secondary ml-3 login-btn-width"
                    >
                      {buttonText.cancel || "Cancel"}
                    </button>
                  )}
                  {onSubmit && (
                    <button
                      color="primary"
                      type="button"
                      autoFocus
                      onClick={(e) => {
                        onSubmit(e, onClose, modalData, submitArg);
                      }}
                      className="btn btn-primary ml-3 login-btn-width"
                      disabled={isSubmitDisable}
                    >
                      {buttonText.submit || "Ok"}
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default CustomDialog1;
