import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllLinksThunk = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/links`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getAllHomePageData = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/website`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
