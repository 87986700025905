import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllEmailsThunk,
  updateEmailsThunk,
  getAllLeadsThunk,
  updateLeadThunk,
} from "./leadsThunk";

const initialState = {
  isLoading: false,
  leads: null,
  emailIds: null,
};

export const httpGetAllEmails = createAsyncThunk(
  "leads/allEmail",
  getAllEmailsThunk
);

export const httpUpdateEmails = createAsyncThunk(
  "leads/updateEmails",
  updateEmailsThunk
);

export const httpGetAllLeads = createAsyncThunk(
  "leads/allLeads",
  getAllLeadsThunk
);

export const httpUpdateLead = createAsyncThunk(
  "leads/updateLead",
  updateLeadThunk
);

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllEmails.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllEmails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.emailIds = payload.emailIds;
    },
    [httpGetAllEmails.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateEmails.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateEmails.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateEmails.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpGetAllLeads.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllLeads.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.emailIds = payload.leads;
    },
    [httpGetAllLeads.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateLead.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateLead.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateLead.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = leadsSlice.actions;
export default leadsSlice.reducer;
