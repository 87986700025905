import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import { TiPlus } from "react-icons/ti";
import { Spinner } from "reactstrap";
import {
  httpAddProduct,
  httpGetProduct,
  httpUpdateProduct,
} from "../../features/products/productsSlice";
import FormInput from "../../components/form/FormInput";
import FormNumber from "../../components/form/FormNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./products.module.css";
import { httpGetAllProductCategory } from "../../features/product-category/productCategorySlice";
import FormSelect from "../../components/form/FormSelect";
import { httpUploadFile } from "../../features/sections/sectionsSlice";
import { openDialog } from "../../features/dialog/dialogSlice1";

function AddProductForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const file = useRef(null);
  const type = useRef("");
  const selIndex = useRef(null);
  const { pId, uId, pType } = useParams();
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [subSubCatData, setSubSubCatData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    gallery: [],
    pageName: "",
    catId: "",
    subCatId: "",
    subsubCatId: "",
    stock: 0,
    p1: {
      title: "",
      price: 0,
      text: "",
    },
    p2: {
      title: "",
      price: 0,
      text: "",
    },
    hand: {
      title: "",
      text: "",
      details: [""],
    },
    spec1: {
      title: "",
      text: "",
      details: [0],
    },
    spec2: {
      title: "",
      text: "",
      details: [
        {
          name: "",
          image: "",
          price: 0,
        },
      ],
    },
    spec3: {
      title: "",
      text: "",
      details: [
        {
          name: "",
          image: "",
          price: 0,
        },
      ],
    },
    spec4: {
      title: "",
      text1: "",
      text2: "",
    },
    code: {
      text1: "",
      text2: "",
      text3: "",
    },
  });

  useEffect(() => {
    dispatch(httpGetAllProductCategory())
      .then((res) => {
        const catArr = res?.payload?.categories;
        setCatData(catArr);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res?.payload);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (pId) {
      setIsLoading(true);
      dispatch(httpGetProduct(pId))
        .then((res) => {
          const objData = res?.payload?.product;
          setFormData({
            ...objData,
            catId: objData?.catId?._id,
            subCatId: objData?.subCatId?._id,
            subsubCatId: objData?.subsubCatId?._id,
          });

          const subCat = catData?.find((dt) => dt._id === objData?.catId?._id);
          const subSubCat = subCat?.children?.find(
            (dt) => dt._id === objData?.subCatId?._id
          );
          setSubCatData(subCat?.children);
          setSubSubCatData(subSubCat?.children);
          setIsLoading(false);
        })
        .catch((res) => {
          toast.error(res?.payload);
          setIsLoading(false);
        });
    }
  }, [catData, pId]);

  useEffect(() => {
    if (uId === "2") {
      setFormData((prev) => ({
        ...prev,
        spec5: {
          title: "",
          text: "",
          details: [0],
        },
        spec6: {
          title: "",
          text: "",
          details: [0],
        },
        spec7: {
          title: "",
          text: "",
          details: [0],
        },
        spec8: {
          title: "",
          text: "",
          details: [
            {
              name: "",
              image: "",
              price: 0,
            },
          ],
        },
        spec9: {
          title: "",
          text: "",
          details: [
            {
              name: "",
              image: "",
              price: 0,
            },
          ],
        },
        spec10: {
          title: "",
          text: "",
          details: [
            {
              name: "",
              image: "",
              price: 0,
            },
          ],
        },
        spec11: {
          title: "",
          text: "",
          details: [
            {
              name: "",
              image: "",
              price: 0,
            },
          ],
        },
        spec12: {
          title: "",
          text: "",
          details: [
            {
              name: "",
              image: "",
              price: 0,
            },
          ],
        },
        spec13: {
          title: "",
          text: "",
          details: [
            {
              name: "",
              image: "",
              price: 0,
            },
          ],
        },
        spec14: {
          title: "",
          text: "",
          details: [
            {
              name: "",
              image: "",
              price: 0,
            },
          ],
        },
      }));
    }
  }, [uId]);

  useEffect(() => {
    const subCat = catData?.find((dt) => dt._id === formData?.catId);
    setSubCatData(subCat?.children);
  }, [formData?.catId]);

  useEffect(() => {
    const subSubCat = subCatData?.find((dt) => dt._id === formData?.subCatId);
    setSubSubCatData(subSubCat?.children);
  }, [formData?.subCatId]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    const submitObj = { ...formData };
    if (pId) {
      if (formData?.uiId !== "2" && formData?.uiId !== 2) {
        submitObj.spec5 && delete submitObj.spec5;
        submitObj.spec6 && delete submitObj.spec6;
        submitObj.spec7 && delete submitObj.spec7;
        submitObj.spec8 && delete submitObj.spec8;
        submitObj.spec9 && delete submitObj.spec9;
        submitObj.spec10 && delete submitObj.spec10;
        submitObj.spec11 && delete submitObj.spec11;
        submitObj.spec12 && delete submitObj.spec12;
        submitObj.spec13 && delete submitObj.spec13;
        submitObj.spec14 && delete submitObj.spec14;
      }

      dispatch(httpUpdateProduct(submitObj))
        .then((res) => {
          if (res?.payload?.status) {
            toast.success(res?.payload?.msg);
            navigate("/products");
          } else {
            toast.error(res?.payload?.errors?.[0]?.msg);
          }
          setIsSaving(false);
        })
        .catch((res) => {
          toast.error(res?.payload);
          setIsSaving(false);
        });
    } else {
      submitObj.uiId = uId;
      submitObj.prodType = pType;
      dispatch(httpAddProduct(submitObj))
        .then((res) => {
          if (res?.payload?.status) {
            toast.success(res?.payload?.msg);
            navigate("/products");
          } else {
            toast.error(res?.payload?.errors?.[0]?.msg);
          }
          setIsSaving(false);
        })
        .catch((res) => {
          toast.error(res?.payload);
          setIsSaving(false);
        });
    }
  };

  const openImageModal = (src) => {
    dispatch(
      openDialog({
        data: {
          footer: false,
          title: `Upload Image`,
          src: src || imgSrc,
          handleSaveImg: handleSaveImg,
          handleCancel: handleCancel,
        },
        path: `ChangeImage`,
      })
    );
  };

  const fileSelect = async (e, type1, i) => {
    try {
      e.preventDefault();
      type.current = type1;
      selIndex.current = i;

      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      if (!reader) return;
      reader.onload = () => {
        setImgSrc(reader.result?.toString());
        openImageModal(reader.result?.toString());
        e.target.value = null;
      };
      reader.readAsDataURL(files[0]);
    } catch (error) {}
  };

  const handleSaveImg = (file) => {
    setImgSrc(null);
    try {
      const formDt = new FormData();
      formDt.append("photo", file);
      let tempType = type.current;
      const selInd = selIndex.current;
      dispatch(httpUploadFile(formDt))
        .then((res) => {
          const url = res?.payload?.url;
          if (tempType.length > 0) {
            const temp = [...formData?.[tempType]?.details];
            temp[selInd].image = url;
            setFormData((prev) => ({
              ...prev,
              [tempType]: {
                ...prev?.[tempType],
                details: temp,
              },
            }));
          } else {
            setFormData((prev) => ({
              ...prev,
              gallery: [url],
            }));
          }
        })
        .catch((res) => {
          toast.error(res.payload);
        });
    } catch (e) {
    } finally {
      type.current = "";
      selIndex.current = null;
    }
  };

  const handleCancel = () => {
    setImgSrc(null);
    type.current = "";
    selIndex.current = null;
  };

  return (
    <div className="h-100 d-flex flex-column overflow-hidden">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h4 className="page-title mb-0">{pId ? "Edit" : "Add"} Product</h4>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-primary mx-3"
            onClick={() => navigate("/products")}
          >
            Back
          </button>
        </div>
      </div>
      <>
        <div className="card mt-4 mb-0 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            {isLoading && <Spinner className="m-1" color="info" />}
            {!isLoading && (
              <form id="add-product-form" onSubmit={handleFormSubmit}>
                <div className="row mb-3">
                  <div className="col-6">
                    <div className="mb-3">
                      <FormInput
                        label="Product Name"
                        dom={{
                          required: true,
                          type: "text",
                          placeholder: "RAINMAKER - STOCK AND PERSONAL",
                          onChange: (e) => {
                            setFormData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }));
                          },
                          value: formData?.name,
                        }}
                      />
                    </div>
                    <div className="">
                      <FormInput
                        label="Page Name"
                        dom={{
                          required: true,
                          type: "text",
                          placeholder: "BUILD MY RAINMAKER",
                          onChange: (e) => {
                            setFormData((prev) => ({
                              ...prev,
                              pageName: e.target.value,
                            }));
                          },
                          value: formData?.pageName,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6 d-flex">
                    <div
                      className={styles.galleryWrapper}
                      onClick={() => file.current.click()}
                    >
                      {formData?.gallery?.[0] ? (
                        <>
                          <img src={formData?.gallery?.[0]} alt="product" />
                          <div
                            className={`position-absolute ${styles.removeImage}`}
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                gallery: [],
                              }))
                            }
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-xmark"
                              className={styles.xIcon}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon="fa-solid fa-arrow-up-from-bracket"
                            className={`${styles.addPageIcon}`}
                          />
                          <input
                            type="file"
                            ref={file}
                            className="d-none"
                            accept="image/*"
                            onChange={(e) => fileSelect(e, "")}
                          />
                        </>
                      )}
                    </div>
                    <label className="mx-3">Product Gallery</label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4">
                    <FormSelect
                      name="font-dropdown"
                      placeholder="CATEGORY"
                      label="Product Category"
                      className={styles.formSelect}
                      onChange={(e, other) => {
                        if (e) {
                          setFormData((prev) => ({
                            ...prev,
                            catId: e.value,
                            subCatId: "",
                            subsubCatId: "",
                          }));
                        }
                      }}
                      value={{
                        label: formData?.catId,
                        value: formData?.catId,
                      }}
                      fullwidth={true}
                      options={
                        catData?.length > 0
                          ? catData?.map((dt) => ({
                              label: dt?.name,
                              value: dt?._id,
                            }))
                          : []
                      }
                      required={true}
                      isClearable={false}
                      isSearchable={false}
                      wrapperClass={styles.fontWrapClass}
                    />
                  </div>
                  <div className="col-4">
                    <FormSelect
                      name="font-dropdown"
                      placeholder="SUB CATEGORY"
                      label="Product Sub Category"
                      className={styles.formSelect}
                      onChange={(e, other) => {
                        if (e) {
                          setFormData((prev) => ({
                            ...prev,
                            subCatId: e.value,
                            subsubCatId: "",
                          }));
                        }
                      }}
                      value={{
                        label: formData?.subCatId,
                        value: formData?.subCatId,
                      }}
                      fullwidth={true}
                      options={
                        subCatData?.length > 0
                          ? subCatData?.map((dt) => ({
                              label: dt?.name,
                              value: dt?._id,
                            }))
                          : []
                      }
                      required={true}
                      isClearable={false}
                      isSearchable={false}
                      wrapperClass={styles.fontWrapClass}
                    />
                  </div>
                  <div className="col-4">
                    <FormSelect
                      name="font-dropdown"
                      label="Product Sub Sub Category"
                      placeholder="SUB SUB CATEGORY"
                      className={styles.formSelect}
                      onChange={(e, other) => {
                        if (e)
                          setFormData((prev) => ({
                            ...prev,
                            subsubCatId: e.value,
                          }));
                      }}
                      value={{
                        label: formData?.subsubCatId,
                        value: formData?.subsubCatId,
                      }}
                      fullwidth={true}
                      options={
                        subSubCatData?.length > 0
                          ? subSubCatData?.map((dt) => ({
                              label: dt?.name,
                              value: dt?._id,
                            }))
                          : []
                      }
                      isClearable={false}
                      isSearchable={false}
                      wrapperClass={styles.fontWrapClass}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4">
                    <FormNumber
                      label="Product Stock"
                      dom={{
                        placeholder: "PRODUCT STOCK",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            stock: Number(e.target.value),
                          }));
                        },
                        value: formData?.stock,
                      }}
                    />
                  </div>
                </div>

                {/* p1 */}
                <div className="row mb-3">
                  <div className="col-3">
                    <FormInput
                      label="P1"
                      dom={{
                        placeholder:
                          uId === "2" ||
                          formData?.uiId === "2" ||
                          uId === 2 ||
                          formData?.uiId === 2
                            ? "CUSTOM"
                            : "STOCK",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            p1: {
                              ...prev?.p1,
                              title: e.target.value,
                            },
                          }));
                        },
                        value: formData?.p1?.title,
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <FormNumber
                      label="P1 Price USD"
                      dom={{
                        placeholder: "PRICE USD",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            p1: {
                              ...prev?.p1,
                              price: Number(e.target.value),
                            },
                          }));
                        },
                        value: formData?.p1?.price,
                      }}
                    />
                  </div>
                  <div className="col-7">
                    <FormInput
                      label="P1 Text"
                      dom={{
                        placeholder:
                          "FACE ANGLE,LIE ANGLE, LENGTHS, MULTIPLE GRIPS & SHIFT OPTIONS ",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            p1: {
                              ...prev?.p1,
                              text: e.target.value,
                            },
                          }));
                        },
                        value: formData?.p1?.text,
                      }}
                    />
                  </div>
                </div>

                {/* p2 */}
                <div className="row mb-3">
                  <div className="col-3">
                    <FormInput
                      label="P2"
                      dom={{
                        placeholder:
                          uId === "2" ||
                          formData?.uiId === "2" ||
                          uId === 2 ||
                          formData?.uiId === 2
                            ? "BESPOKE"
                            : "PERSONALIZE ",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            p2: {
                              ...prev?.p2,
                              title: e.target.value,
                            },
                          }));
                        },
                        value: formData?.p2?.title,
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <FormNumber
                      label="P2 Price USD"
                      dom={{
                        required: true,
                        placeholder: "PRICE USD",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            p2: {
                              ...prev?.p2,
                              price: Number(e.target.value),
                            },
                          }));
                        },
                        value: formData?.p2?.price,
                      }}
                    />
                  </div>
                  <div className="col-7">
                    <FormInput
                      label="P2 Text"
                      dom={{
                        placeholder:
                          "FACE ANGLE,LIE ANGLE, LENGTHS, MULTIPLE GRIPS & SHIFT OPTIONS ",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            p2: {
                              ...prev?.p2,
                              text: e.target.value,
                            },
                          }));
                        },
                        value: formData?.p2?.text,
                      }}
                    />
                  </div>
                </div>

                {/* hand */}
                <div className="row mb-3">
                  <div className="col-6">
                    <FormInput
                      label="Hand"
                      dom={{
                        required: true,
                        placeholder: "SELECT HAND",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            hand: {
                              ...prev?.hand,
                              title: e.target.value,
                            },
                          }));
                        },
                        value: formData?.hand?.title,
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <FormInput
                      label="Hand Text"
                      dom={{
                        placeholder: "WHAT ARE YOU RIGHT HANDED OR LEFT",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            hand: {
                              ...prev?.hand,
                              text: e.target.value,
                            },
                          }));
                        },
                        value: formData?.hand?.text,
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 d-flex justify-content-between">
                    <label className="mb-0">Hand Details</label>
                    <TiPlus
                      className={styles.addPageIcon}
                      onClick={() => {
                        const temp = [...formData?.hand?.details];
                        temp.push("");
                        setFormData((prev) => ({
                          ...prev,
                          hand: {
                            ...prev?.hand,
                            details: temp,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="col-9">
                    <div className="row">
                      {formData?.hand?.details.map((val, i) => (
                        <div className="col-2 mb-3" key={i}>
                          <FormInput
                            dom={{
                              required: true,
                              onChange: (e) => {
                                const temp = [...formData?.hand?.details];
                                temp[i] = e.target.value;
                                setFormData((prev) => ({
                                  ...prev,
                                  hand: {
                                    ...prev?.hand,
                                    details: temp,
                                  },
                                }));
                              },
                              value: val,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-1 d-flex">
                    <AiFillDelete
                      className={styles.addPageIcon}
                      onClick={() => {
                        const temp = [...formData?.hand?.details];
                        temp.pop();
                        setFormData((prev) => ({
                          ...prev,
                          hand: {
                            ...prev?.hand,
                            details: temp,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>

                {/* spec1 */}
                <div className="row mb-3">
                  <div className="col-6">
                    <FormInput
                      label="Spec 1"
                      dom={{
                        required: true,
                        placeholder: "PUTTER LENGTH",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec1: {
                              ...prev?.spec1,
                              title: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec1?.title,
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <FormInput
                      label="Spec 1 Text"
                      dom={{
                        placeholder: "( INCHES ) -  STANDARD 34 INCHES",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec1: {
                              ...prev?.spec1,
                              text: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec1?.text,
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 d-flex justify-content-between">
                    <label className="mb-0">Spec 1 Details</label>
                    <TiPlus
                      className={styles.addPageIcon}
                      onClick={() => {
                        const temp = [...formData?.spec1?.details];
                        temp.push(0);
                        setFormData((prev) => ({
                          ...prev,
                          spec1: {
                            ...prev?.spec1,
                            details: temp,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="col-9">
                    <div className="row">
                      {formData?.spec1?.details.map((val, i) => (
                        <div className="col-1 mb-3" key={i}>
                          <FormNumber
                            dom={{
                              required: true,
                              onChange: (e) => {
                                const temp = [...formData?.spec1?.details];
                                temp[i] = Number(e.target.value);
                                setFormData((prev) => ({
                                  ...prev,
                                  spec1: {
                                    ...prev?.spec1,
                                    details: temp,
                                  },
                                }));
                              },
                              value: val,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-1 d-flex">
                    <AiFillDelete
                      className={styles.addPageIcon}
                      onClick={() => {
                        const temp = [...formData?.spec1?.details];
                        temp.pop();
                        setFormData((prev) => ({
                          ...prev,
                          spec1: {
                            ...prev?.spec1,
                            details: temp,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>

                {/* spec2 */}
                <div className="row mb-3">
                  <div className="col-6">
                    <FormInput
                      label="Spec 2"
                      dom={{
                        required: true,
                        placeholder: "SELECT MY GRIP",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec2: {
                              ...prev?.spec2,
                              title: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec2?.title,
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <FormInput
                      label="Spec 2 Text"
                      dom={{
                        placeholder: "GRIP SHORT TEXT",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec2: {
                              ...prev?.spec2,
                              text: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec2?.text,
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 d-flex justify-content-between">
                    <label className="mb-0">Spec 2 Details</label>
                    <TiPlus
                      className={styles.addPageIcon}
                      onClick={() => {
                        const temp = [...formData?.spec2?.details];
                        temp.push({
                          name: "",
                          image: "",
                          price: 0,
                        });
                        setFormData((prev) => ({
                          ...prev,
                          spec2: {
                            ...prev?.spec2,
                            details: temp,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="col-10">
                    {formData?.spec2?.details.map((data, i) => (
                      <div className="row mb-3" key={i}>
                        <div className="col-7">
                          <FormInput
                            dom={{
                              placeholder: "NO GRIP ",
                              required: true,
                              onChange: (e) => {
                                const temp = [...formData?.spec2?.details];
                                temp[i].name = e.target.value;
                                setFormData((prev) => ({
                                  ...prev,
                                  spec2: {
                                    ...prev?.spec2,
                                    details: temp,
                                  },
                                }));
                              },
                              value: data?.name,
                            }}
                          />
                        </div>
                        <div className="col-2 d-flex align-items-center">
                          <div className={styles.detailsImg}>
                            {data?.image && (
                              <>
                                <img src={data?.image} alt="product" />
                                <div
                                  className={`position-absolute ${styles.removeImage}`}
                                  onClick={(e) => {
                                    const temp = [...formData?.spec2?.details];
                                    temp[i].image = "";
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec2: {
                                        ...prev?.spec2,
                                        details: temp,
                                      },
                                    }));
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-xmark"
                                    className={styles.xIcon}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-arrow-up-from-bracket"
                            className={`ml-3 ${styles.addPageIcon}`}
                            onClick={() =>
                              document.getElementById(`spec2-${i}`).click()
                            }
                          />
                          <input
                            type="file"
                            id={`spec2-${i}`}
                            className="d-none"
                            accept="image/*"
                            onChange={(e) => fileSelect(e, "spec2", i)}
                          />
                        </div>
                        <div className="col-2 d-flex align-items-center">
                          <label className="mb-0 mx-3">USD</label>
                          <FormNumber
                            dom={{
                              required: true,
                              onChange: (e) => {
                                const temp = [...formData?.spec2?.details];
                                temp[i].price = Number(e.target.value);
                                setFormData((prev) => ({
                                  ...prev,
                                  spec2: {
                                    ...prev?.spec2,
                                    details: temp,
                                  },
                                }));
                              },
                              value: data?.price,
                            }}
                          />
                        </div>
                        <div className="col-1">
                          <AiFillDelete
                            className={styles.addPageIcon}
                            onClick={() => {
                              const temp = [...formData?.spec2?.details];
                              temp.splice(i, 1);
                              setFormData((prev) => ({
                                ...prev,
                                spec2: {
                                  ...prev?.spec2,
                                  details: temp,
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* spec3 */}
                <div className="row mb-3">
                  <div className="col-6">
                    <FormInput
                      label="Spec 3"
                      dom={{
                        required: true,
                        placeholder: "SELECT MY SHAFT",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec3: {
                              ...prev?.spec3,
                              title: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec3?.title,
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <FormInput
                      label="Spec 3 Text"
                      dom={{
                        required: true,
                        placeholder: "SHAFT SHOT TEXT",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec3: {
                              ...prev?.spec3,
                              text: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec3?.text,
                      }}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 d-flex justify-content-between">
                    <label className="mb-0">Spec 3 Details</label>
                    <TiPlus
                      className={styles.addPageIcon}
                      onClick={() => {
                        const temp = [...formData?.spec3?.details];
                        temp.push({
                          name: "",
                          image: "",
                          price: 0,
                        });
                        setFormData((prev) => ({
                          ...prev,
                          spec3: {
                            ...prev?.spec3,
                            details: temp,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="col-10">
                    {formData?.spec3?.details.map((data, i) => (
                      <div className="row mb-3" key={i}>
                        <div className="col-7">
                          <FormInput
                            dom={{
                              placeholder: "NO GRIP",
                              required: true,
                              onChange: (e) => {
                                const temp = [...formData?.spec3?.details];
                                temp[i].name = e.target.value;
                                setFormData((prev) => ({
                                  ...prev,
                                  spec3: {
                                    ...prev?.spec3,
                                    details: temp,
                                  },
                                }));
                              },
                              value: data?.name,
                            }}
                          />
                        </div>
                        <div className="col-2 d-flex align-items-center">
                          <div className={styles.detailsImg}>
                            {data?.image && (
                              <>
                                <img src={data?.image} alt="product" />
                                <div
                                  className={`position-absolute ${styles.removeImage}`}
                                  onClick={(e) => {
                                    const temp = [...formData?.spec3?.details];
                                    temp[i].image = "";
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec3: {
                                        ...prev?.spec3,
                                        details: temp,
                                      },
                                    }));
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-xmark"
                                    className={styles.xIcon}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <FontAwesomeIcon
                            icon="fa-solid fa-arrow-up-from-bracket"
                            className={`ml-3 ${styles.addPageIcon}`}
                            onClick={() =>
                              document.getElementById(`spec3-${i}`).click()
                            }
                          />
                          <input
                            type="file"
                            id={`spec3-${i}`}
                            className="d-none"
                            accept="image/*"
                            onChange={(e) => fileSelect(e, "spec3", i)}
                          />
                        </div>
                        <div className="col-2 d-flex align-items-center">
                          <label className="mb-0 mx-3">USD</label>
                          <FormNumber
                            dom={{
                              required: true,
                              onChange: (e) => {
                                const temp = [...formData?.spec3?.details];
                                temp[i].price = Number(e.target.value);
                                setFormData((prev) => ({
                                  ...prev,
                                  spec3: {
                                    ...prev?.spec3,
                                    details: temp,
                                  },
                                }));
                              },
                              value: data?.price,
                            }}
                          />
                        </div>
                        <div className="col-1">
                          <AiFillDelete
                            className={styles.addPageIcon}
                            onClick={() => {
                              const temp = [...formData?.spec3?.details];
                              temp.splice(i, 1);
                              setFormData((prev) => ({
                                ...prev,
                                spec3: {
                                  ...prev?.spec3,
                                  details: temp,
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* spec4 */}
                <div className="row mb-3">
                  <div className="col-4">
                    <FormInput
                      label="Spec 4"
                      dom={{
                        required: true,
                        placeholder: "ENGRAVE MY NAME",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec4: {
                              ...prev.spec4,
                              title: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec4?.title,
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <FormInput
                      label="Spec 4 Text 1"
                      dom={{
                        required: true,
                        placeholder: "HAND MILLED AND CUSTOM FITTED FOR",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec4: {
                              ...prev.spec4,
                              text1: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec4?.text1,
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <FormInput
                      label="Spec 4 Text 2"
                      dom={{
                        placeholder: "MAX 100 CHARACTERS",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            spec4: {
                              ...prev.spec4,
                              text2: e.target.value,
                            },
                          }));
                        },
                        value: formData?.spec4?.text2,
                      }}
                    />
                  </div>
                </div>

                {(uId === "2" ||
                  formData?.uiId === "2" ||
                  uId === 2 ||
                  formData?.uiId === 2) && (
                  <>
                    {/* spec5 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 5"
                          dom={{
                            required: true,
                            placeholder: "LIE ENGLE",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec5: {
                                  ...prev?.spec5,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec5?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 5 Text"
                          dom={{
                            required: true,

                            placeholder: "( INCHES ) -  STANDARD 34 INCHES",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec5: {
                                  ...prev?.spec5,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec5?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 5 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec5?.details];
                            temp.push(0);
                            setFormData((prev) => ({
                              ...prev,
                              spec5: {
                                ...prev?.spec5,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-9">
                        <div className="row">
                          {formData?.spec5?.details.map((val, i) => (
                            <div className="col-1 mb-3" key={i}>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec5?.details];
                                    temp[i] = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec5: {
                                        ...prev?.spec5,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: val,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-1 d-flex ">
                        <AiFillDelete
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec5?.details];
                            temp.pop();
                            setFormData((prev) => ({
                              ...prev,
                              spec5: {
                                ...prev?.spec5,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>

                    {/* spec6 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 6"
                          dom={{
                            required: true,
                            placeholder: "FACE ANGLE",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec6: {
                                  ...prev?.spec6,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec6?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 6 Text"
                          dom={{
                            placeholder: "( INCHES ) -  STANDARD 34 INCHES",
                            required: true,
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec6: {
                                  ...prev?.spec6,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec6?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 6 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec6?.details];
                            temp.push(0);
                            setFormData((prev) => ({
                              ...prev,
                              spec6: {
                                ...prev?.spec6,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-9">
                        <div className="row">
                          {formData?.spec6?.details.map((val, i) => (
                            <div className="col-1 mb-3" key={i}>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec6?.details];
                                    temp[i] = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec6: {
                                        ...prev?.spec6,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: val,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-1 d-flex ">
                        <AiFillDelete
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec6?.details];
                            temp.pop();
                            setFormData((prev) => ({
                              ...prev,
                              spec6: {
                                ...prev?.spec6,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>

                    {/* spec7 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 7"
                          dom={{
                            required: true,
                            placeholder: "PUTTER HEAD WIGHT",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec7: {
                                  ...prev?.spec7,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec7?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 7 Text"
                          dom={{
                            required: true,
                            placeholder: "( INCHES ) -  STANDARD 34 INCHES",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec7: {
                                  ...prev?.spec7,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec7?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 7 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec7?.details];
                            temp.push(0);
                            setFormData((prev) => ({
                              ...prev,
                              spec7: {
                                ...prev?.spec7,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-9">
                        <div className="row">
                          {formData?.spec7?.details.map((val, i) => (
                            <div className="col-1 mb-3" key={i}>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec7?.details];
                                    temp[i] = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec7: {
                                        ...prev?.spec7,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: val,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-1 d-flex">
                        <AiFillDelete
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec7?.details];
                            temp.pop();
                            setFormData((prev) => ({
                              ...prev,
                              spec7: {
                                ...prev?.spec7,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>

                    {/* spec8 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 8"
                          dom={{
                            required: true,
                            placeholder: "PUTTER FINISH",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec8: {
                                  ...prev?.spec8,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec8?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 8 Text"
                          dom={{
                            placeholder: "PUTTER FINISH SHORT TEXT",
                            required: true,
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec8: {
                                  ...prev?.spec8,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec8?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 8 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec8?.details];
                            temp.push({
                              name: "",
                              image: "",
                              price: 0,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              spec8: {
                                ...prev?.spec8,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-10">
                        {formData?.spec8?.details.map((data, i) => (
                          <div className="row mb-3" key={i}>
                            <div className="col-7">
                              <FormInput
                                dom={{
                                  placeholder: "NO GRIP",
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec8?.details];
                                    temp[i].name = e.target.value;
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec8: {
                                        ...prev?.spec8,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.name,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <div className={styles.detailsImg}>
                                {data?.image && (
                                  <>
                                    <img src={data?.image} alt="product" />
                                    <div
                                      className={`position-absolute ${styles.removeImage}`}
                                      onClick={(e) => {
                                        const temp = [
                                          ...formData?.spec8?.details,
                                        ];
                                        temp[i].image = "";
                                        setFormData((prev) => ({
                                          ...prev,
                                          spec8: {
                                            ...prev?.spec8,
                                            details: temp,
                                          },
                                        }));
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        className={styles.xIcon}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon
                                icon="fa-solid fa-arrow-up-from-bracket"
                                className={`ml-3 ${styles.addPageIcon}`}
                                onClick={() =>
                                  document.getElementById(`spec8-${i}`).click()
                                }
                              />
                              <input
                                type="file"
                                id={`spec8-${i}`}
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => fileSelect(e, "spec8", i)}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <label className="mb-0 mx-3">USD</label>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec8?.details];
                                    temp[i].price = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec8: {
                                        ...prev?.spec8,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.price,
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <AiFillDelete
                                className={styles.addPageIcon}
                                onClick={() => {
                                  const temp = [...formData?.spec8?.details];
                                  temp.splice(i, 1);
                                  setFormData((prev) => ({
                                    ...prev,
                                    spec8: {
                                      ...prev?.spec8,
                                      details: temp,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* spec9 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 9"
                          dom={{
                            required: true,
                            placeholder: "PUTTER FACE INSERT",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec9: {
                                  ...prev?.spec9,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec9?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 9 Text"
                          dom={{
                            placeholder: "PUTTER FACE INSERT SHORT TEXT",
                            required: true,
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec9: {
                                  ...prev?.spec9,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec9?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 9 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec9?.details];
                            temp.push({
                              name: "",
                              image: "",
                              price: 0,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              spec9: {
                                ...prev?.spec9,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-10">
                        {formData?.spec9?.details.map((data, i) => (
                          <div className="row mb-3" key={i}>
                            <div className="col-7">
                              <FormInput
                                dom={{
                                  placeholder: "NO GRIP",
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec9?.details];
                                    temp[i].name = e.target.value;
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec9: {
                                        ...prev?.spec9,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.name,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <div className={styles.detailsImg}>
                                {data?.image && (
                                  <>
                                    <img src={data?.image} alt="product" />
                                    <div
                                      className={`position-absolute ${styles.removeImage}`}
                                      onClick={(e) => {
                                        const temp = [
                                          ...formData?.spec9?.details,
                                        ];
                                        temp[i].image = "";
                                        setFormData((prev) => ({
                                          ...prev,
                                          spec9: {
                                            ...prev?.spec9,
                                            details: temp,
                                          },
                                        }));
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        className={styles.xIcon}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon
                                icon="fa-solid fa-arrow-up-from-bracket"
                                className={`ml-3 ${styles.addPageIcon}`}
                                onClick={() =>
                                  document.getElementById(`spec9-${i}`).click()
                                }
                              />
                              <input
                                type="file"
                                id={`spec9-${i}`}
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => fileSelect(e, "spec9", i)}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <label className="mb-0 mx-3">USD</label>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec9?.details];
                                    temp[i].price = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec9: {
                                        ...prev?.spec9,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.price,
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <AiFillDelete
                                className={styles.addPageIcon}
                                onClick={() => {
                                  const temp = [...formData?.spec9?.details];
                                  temp.splice(i, 1);
                                  setFormData((prev) => ({
                                    ...prev,
                                    spec9: {
                                      ...prev?.spec9,
                                      details: temp,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* spec10 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 10"
                          dom={{
                            required: true,
                            placeholder: "PUTTER FACE GROOVES",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec10: {
                                  ...prev?.spec10,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec10?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 10 Text"
                          dom={{
                            placeholder: "PUTTER FACE GROOVES SHORT TEXT",
                            required: true,
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec10: {
                                  ...prev?.spec10,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec10?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 10 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec10?.details];
                            temp.push({
                              name: "",
                              image: "",
                              price: 0,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              spec10: {
                                ...prev?.spec10,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-10">
                        {formData?.spec10?.details.map((data, i) => (
                          <div className="row mb-3" key={i}>
                            <div className="col-7">
                              <FormInput
                                dom={{
                                  placeholder: "NO GRIP",
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec10?.details];
                                    temp[i].name = e.target.value;
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec10: {
                                        ...prev?.spec10,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.name,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <div className={styles.detailsImg}>
                                {data?.image && (
                                  <>
                                    <img src={data?.image} alt="product" />
                                    <div
                                      className={`position-absolute ${styles.removeImage}`}
                                      onClick={(e) => {
                                        const temp = [
                                          ...formData?.spec10?.details,
                                        ];
                                        temp[i].image = "";
                                        setFormData((prev) => ({
                                          ...prev,
                                          spec10: {
                                            ...prev?.spec10,
                                            details: temp,
                                          },
                                        }));
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        className={styles.xIcon}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon
                                icon="fa-solid fa-arrow-up-from-bracket"
                                className={`ml-3 ${styles.addPageIcon}`}
                                onClick={() =>
                                  document.getElementById(`spec10-${i}`).click()
                                }
                              />
                              <input
                                type="file"
                                id={`spec10-${i}`}
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => fileSelect(e, "spec10", i)}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <label className="mb-0 mx-3">USD</label>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec10?.details];
                                    temp[i].price = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec10: {
                                        ...prev?.spec10,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.price,
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <AiFillDelete
                                className={styles.addPageIcon}
                                onClick={() => {
                                  const temp = [...formData?.spec10?.details];
                                  temp.splice(i, 1);
                                  setFormData((prev) => ({
                                    ...prev,
                                    spec10: {
                                      ...prev?.spec10,
                                      details: temp,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* spec11 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 11"
                          dom={{
                            required: true,
                            placeholder: "PUTTER TOP MARKINGS",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec11: {
                                  ...prev?.spec11,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec11?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 11 Text"
                          dom={{
                            placeholder: "PUTTER TOP MARKINGS SHORT TEXT",
                            required: true,
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec11: {
                                  ...prev?.spec11,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec11?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 11 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec11?.details];
                            temp.push({
                              name: "",
                              image: "",
                              price: 0,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              spec11: {
                                ...prev?.spec11,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-10">
                        {formData?.spec11?.details.map((data, i) => (
                          <div className="row mb-3" key={i}>
                            <div className="col-7">
                              <FormInput
                                dom={{
                                  required: true,
                                  placeholder: "NO GRIP",
                                  onChange: (e) => {
                                    const temp = [...formData?.spec11?.details];
                                    temp[i].name = e.target.value;
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec11: {
                                        ...prev?.spec11,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.name,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <div className={styles.detailsImg}>
                                {data?.image && (
                                  <>
                                    <img src={data?.image} alt="product" />
                                    <div
                                      className={`position-absolute ${styles.removeImage}`}
                                      onClick={(e) => {
                                        const temp = [
                                          ...formData?.spec11?.details,
                                        ];
                                        temp[i].image = "";
                                        setFormData((prev) => ({
                                          ...prev,
                                          spec11: {
                                            ...prev?.spec11,
                                            details: temp,
                                          },
                                        }));
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        className={styles.xIcon}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon
                                icon="fa-solid fa-arrow-up-from-bracket"
                                className={`ml-3 ${styles.addPageIcon}`}
                                onClick={() =>
                                  document.getElementById(`spec11-${i}`).click()
                                }
                              />
                              <input
                                type="file"
                                id={`spec11-${i}`}
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => fileSelect(e, "spec11", i)}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <label className="mb-0 mx-3">USD</label>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec11?.details];
                                    temp[i].price = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec11: {
                                        ...prev?.spec11,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.price,
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <AiFillDelete
                                className={styles.addPageIcon}
                                onClick={() => {
                                  const temp = [...formData?.spec11?.details];
                                  temp.splice(i, 1);
                                  setFormData((prev) => ({
                                    ...prev,
                                    spec11: {
                                      ...prev?.spec11,
                                      details: temp,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* spec12 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 12"
                          dom={{
                            required: true,
                            placeholder: "PAINT FILLS",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec12: {
                                  ...prev?.spec12,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec12?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 12 Text"
                          dom={{
                            placeholder: "PAINT FILLS SHORT TEXT",
                            required: true,
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec12: {
                                  ...prev?.spec12,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec12?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 12 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec12?.details];
                            temp.push({
                              name: "",
                              image: "",
                              price: 0,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              spec12: {
                                ...prev?.spec12,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-10">
                        {formData?.spec12?.details.map((data, i) => (
                          <div className="row mb-3" key={i}>
                            <div className="col-7">
                              <FormInput
                                dom={{
                                  placeholder: "NO GRIP",
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec12?.details];
                                    temp[i].name = e.target.value;
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec12: {
                                        ...prev?.spec12,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.name,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <div className={styles.detailsImg}>
                                {data?.image && (
                                  <>
                                    <img src={data?.image} alt="product" />
                                    <div
                                      className={`position-absolute ${styles.removeImage}`}
                                      onClick={(e) => {
                                        const temp = [
                                          ...formData?.spec12?.details,
                                        ];
                                        temp[i].image = "";
                                        setFormData((prev) => ({
                                          ...prev,
                                          spec12: {
                                            ...prev?.spec12,
                                            details: temp,
                                          },
                                        }));
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        className={styles.xIcon}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon
                                icon="fa-solid fa-arrow-up-from-bracket"
                                className={`ml-3 ${styles.addPageIcon}`}
                                onClick={() =>
                                  document.getElementById(`spec12-${i}`).click()
                                }
                              />
                              <input
                                type="file"
                                id={`spec12-${i}`}
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => fileSelect(e, "spec12", i)}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <label className="mb-0 mx-3">USD</label>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec12?.details];
                                    temp[i].price = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec12: {
                                        ...prev?.spec12,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.price,
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <AiFillDelete
                                className={styles.addPageIcon}
                                onClick={() => {
                                  const temp = [...formData?.spec12?.details];
                                  temp.splice(i, 1);
                                  setFormData((prev) => ({
                                    ...prev,
                                    spec12: {
                                      ...prev?.spec12,
                                      details: temp,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* spec13 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 13"
                          dom={{
                            required: true,
                            placeholder: "MY HEADCOVER",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec13: {
                                  ...prev?.spec13,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec13?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 13 Text"
                          dom={{
                            required: true,
                            placeholder: "MY HEADCOVER SHORT TEXT",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec13: {
                                  ...prev?.spec13,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec13?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 13 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec13?.details];
                            temp.push({
                              name: "",
                              image: "",
                              price: 0,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              spec13: {
                                ...prev?.spec13,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-10">
                        {formData?.spec13?.details.map((data, i) => (
                          <div className="row mb-3" key={i}>
                            <div className="col-7">
                              <FormInput
                                dom={{
                                  placeholder: "NO GRIP",
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec13?.details];
                                    temp[i].name = e.target.value;
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec13: {
                                        ...prev?.spec13,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.name,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <div className={styles.detailsImg}>
                                {data?.image && (
                                  <>
                                    <img src={data?.image} alt="product" />
                                    <div
                                      className={`position-absolute ${styles.removeImage}`}
                                      onClick={(e) => {
                                        const temp = [
                                          ...formData?.spec13?.details,
                                        ];
                                        temp[i].image = "";
                                        setFormData((prev) => ({
                                          ...prev,
                                          spec13: {
                                            ...prev?.spec13,
                                            details: temp,
                                          },
                                        }));
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        className={styles.xIcon}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon
                                icon="fa-solid fa-arrow-up-from-bracket"
                                className={`ml-3 ${styles.addPageIcon}`}
                                onClick={() =>
                                  document.getElementById(`spec13-${i}`).click()
                                }
                              />
                              <input
                                type="file"
                                id={`spec13-${i}`}
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => fileSelect(e, "spec13", i)}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <label className="mb-0 mx-3">USD</label>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec13?.details];
                                    temp[i].price = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec13: {
                                        ...prev?.spec13,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.price,
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <AiFillDelete
                                className={styles.addPageIcon}
                                onClick={() => {
                                  const temp = [...formData?.spec13?.details];
                                  temp.splice(i, 1);
                                  setFormData((prev) => ({
                                    ...prev,
                                    spec13: {
                                      ...prev?.spec13,
                                      details: temp,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* spec14 */}
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormInput
                          label="Spec 14"
                          dom={{
                            required: true,
                            placeholder: "PUTTER SWING WEIGHT",
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec14: {
                                  ...prev?.spec14,
                                  title: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec14?.title,
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <FormInput
                          label="Spec 14 Text"
                          dom={{
                            placeholder: "PUTTER SWING WEIGHT SHORT TEXT",
                            required: true,
                            onChange: (e) => {
                              setFormData((prev) => ({
                                ...prev,
                                spec14: {
                                  ...prev?.spec14,
                                  text: e.target.value,
                                },
                              }));
                            },
                            value: formData?.spec14?.text,
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2 d-flex justify-content-between">
                        <label className="mb-0">Spec 14 Details</label>
                        <TiPlus
                          className={styles.addPageIcon}
                          onClick={() => {
                            const temp = [...formData?.spec14?.details];
                            temp.push({
                              name: "",
                              image: "",
                              price: 0,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              spec14: {
                                ...prev?.spec14,
                                details: temp,
                              },
                            }));
                          }}
                        />
                      </div>
                      <div className="col-10">
                        {formData?.spec14?.details.map((data, i) => (
                          <div className="row mb-3" key={i}>
                            <div className="col-7">
                              <FormInput
                                dom={{
                                  placeholder: "NO GRIP",
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec14?.details];
                                    temp[i].name = e.target.value;
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec14: {
                                        ...prev?.spec14,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.name,
                                }}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <div className={styles.detailsImg}>
                                {data?.image && (
                                  <>
                                    <img src={data?.image} alt="product" />
                                    <div
                                      className={`position-absolute ${styles.removeImage}`}
                                      onClick={(e) => {
                                        const temp = [
                                          ...formData?.spec14?.details,
                                        ];
                                        temp[i].image = "";
                                        setFormData((prev) => ({
                                          ...prev,
                                          spec14: {
                                            ...prev?.spec14,
                                            details: temp,
                                          },
                                        }));
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"
                                        className={styles.xIcon}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <FontAwesomeIcon
                                icon="fa-solid fa-arrow-up-from-bracket"
                                className={`ml-3 ${styles.addPageIcon}`}
                                onClick={() =>
                                  document.getElementById(`spec14-${i}`).click()
                                }
                              />
                              <input
                                type="file"
                                id={`spec14-${i}`}
                                className="d-none"
                                accept="image/*"
                                onChange={(e) => fileSelect(e, "spec14", i)}
                              />
                            </div>
                            <div className="col-2 d-flex align-items-center">
                              <label className="mb-0 mx-3">USD</label>
                              <FormNumber
                                dom={{
                                  required: true,
                                  onChange: (e) => {
                                    const temp = [...formData?.spec14?.details];
                                    temp[i].price = Number(e.target.value);
                                    setFormData((prev) => ({
                                      ...prev,
                                      spec14: {
                                        ...prev?.spec14,
                                        details: temp,
                                      },
                                    }));
                                  },
                                  value: data?.price,
                                }}
                              />
                            </div>
                            <div className="col-1">
                              <AiFillDelete
                                className={styles.addPageIcon}
                                onClick={() => {
                                  const temp = [...formData?.spec14?.details];
                                  temp.splice(i, 1);
                                  setFormData((prev) => ({
                                    ...prev,
                                    spec14: {
                                      ...prev?.spec14,
                                      details: temp,
                                    },
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {/* code-text */}
                <div className="row mb-3">
                  <div className="col-4">
                    <FormInput
                      label="Code Text 1"
                      dom={{
                        placeholder: "IF APPLICABLE",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            code: {
                              ...prev?.code,
                              text1: e.target.value,
                            },
                          }));
                        },
                        value: formData?.code?.text1,
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <FormInput
                      label="Code Text 2"
                      dom={{
                        required: true,
                        placeholder: "PASTE GIFT CARD CODE",
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            code: {
                              ...prev?.code,
                              text2: e.target.value,
                            },
                          }));
                        },
                        value: formData?.code?.text2,
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <FormInput
                      label="Code Text 3"
                      dom={{
                        placeholder: "ENTER MERCHANT/RETAIL CODE",
                        required: true,
                        onChange: (e) => {
                          setFormData((prev) => ({
                            ...prev,
                            code: {
                              ...prev?.code,
                              text3: e.target.value,
                            },
                          }));
                        },
                        value: formData?.code?.text3,
                      }}
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
          {/* )} */}
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="btn btn-success text-center ml-4"
            type="submit"
            form="add-product-form"
            disabled={isSaving}
          >
            {pId ? "Update" : "Submit"}
          </button>
        </div>
      </>
    </div>
  );
}

export default AddProductForm;
