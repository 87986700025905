import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  httpVerifyPassword,
  httpGetOTP,
  httpVerifyOTP,
  httpGetCountryCode,
  clearOtpId,
} from "../../features/admin/adminSlice";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/form/FormInput";
import PhoneInput from "../../components/form/PhoneInput";
import { useFormik } from "formik";
import {
  validationSchema1,
  validationSchema2,
  validationSchema3,
  validationSchema4,
} from "./validationSchema";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import styles from "./login.module.css";
import { openDialog } from "../../features/dialog/dialogSlice";
import { toast } from "react-toastify";

const Login = () => {
  const [secPassword, setSecPassword] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [isSuccess, setIsSuccess] = useState(false);
  const [codesArr, setCodesArr] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { admin } = useSelector((store) => {
    return store.admin;
  });
  const {
    isLoading,
    otpId,
    verifyPass,
    otpPhoneVerificationError,
    otpPhoneVerificationSuccess,
    otpEmailVerificationError,
    otpEmailVerificationSuccess,
  } = useSelector((store) => {
    return store.admin;
  });

  const formik1 = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = { email: values.email, password: values.password };
      dispatch(httpVerifyPassword(data));
    },
  });

  const formik2 = useFormik({
    initialValues: {
      phone: "",
      email: "",
    },
    validationSchema: validationSchema2,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        countryCode: countryCode,
        mobile: values.phone,
        email: values.email,
      };
      dispatch(httpGetOTP(data));
    },
  });

  const formik3 = useFormik({
    initialValues: {
      potp: "",
    },
    validationSchema: validationSchema3,
    enableReinitialize: true,
    onSubmit: (values) => {
      callFun(values.potp, "phone");
    },
  });

  const formik4 = useFormik({
    initialValues: {
      eotp: "",
    },
    validationSchema: validationSchema4,
    enableReinitialize: true,
    onSubmit: (values) => {
      callFun(values.eotp, "email");
    },
  });

  const callFun = async (value, mode) => {
    if (mode === "phone") {
      const data = { otpId, otpCodeMobile: value, mode: "phone" };
      dispatch(httpVerifyOTP(data));
    } else if (mode === "email") {
      const data = { otpId, otpCodeEmail: value, mode: "email" };
      dispatch(httpVerifyOTP(data));
    }
  };

  useEffect(() => {
    dispatch(httpGetCountryCode())
      .then((res) => setCodesArr(res.payload.countryCodes))
      .catch((res) => toast.error(res.payload));
  }, []);

  useEffect(() => {
    if (otpPhoneVerificationSuccess && otpEmailVerificationSuccess)
      setIsSuccess(true);
    if (otpPhoneVerificationError || otpEmailVerificationError)
      setIsSuccess(false);
  }, [
    otpPhoneVerificationError,
    otpPhoneVerificationSuccess,
    otpEmailVerificationError,
    otpEmailVerificationSuccess,
  ]);

  useEffect(() => {
    if (verifyPass) {
      const modules = admin ? JSON.parse(JSON.stringify(admin.modules)) : [];
      const homeData = modules.find((data) => data.path === "/home");

      homeData
        ? navigate(`${homeData?.path}/${homeData?.pageId}`)
        : navigate("/");
    }
  }, [verifyPass, navigate]);

  useEffect(() => {
    let interval = 0;
    let timer = 120;
    if (otpId) {
      interval = setInterval(() => {
        timer = timer - 1;
        let el = document.getElementById("_timer");
        el ? (el.innerHTML = secondsToTime(timer)) : clearInterval(interval);
        if (timer === 0) {
          clearInterval(interval);
          timer = 120;
          dispatch(clearOtpId());
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [otpId, dispatch]);

  const secondsToTime = (secs) => {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return `0${minutes}:${seconds > 9 ? seconds : "0" + seconds}`;
  };

  const handleModalOpen = () => {
    dispatch(
      openDialog({
        data: {
          cancelBtn: true,
          title: "CONTACT THE TECHNOLOGY TEAM",
          titleClass: "fs20",
        },
        path: "ContactForm",
      })
    );
  };

  return (
    <div style={{ width: 495 }}>
      {secPassword ? (
        <>
          <h2 className={`logo-txt w-700 mb-4 mt-3`}>MY SECURITY PASSWORD</h2>
          <form id="userForm" onSubmit={formik1.handleSubmit}>
            <FormInput
              label="ENTER EMAIL ID"
              dom={{
                type: "text",
                name: "email",
                onChange: (e) => {
                  formik1.setFieldValue("email", e.target.value, true);
                },
                value: formik1.values.email,
                placeholder: "EMAIL",
              }}
              error={formik1.touched.email && Boolean(formik1.errors.email)}
              errorMessage={formik1.touched.email && formik1.errors.email}
              wrapperClass="mt-4"
              fullwidth={true}
            />
            <FormInput
              label="ENTER PASSWORD"
              dom={{
                type: "password",
                name: "password",
                onChange: (e) => {
                  formik1.setFieldValue("password", e.target.value, true);
                },
                value: formik1.values.password,
                placeholder: "PASSWORD",
              }}
              error={
                formik1.touched.password && Boolean(formik1.errors.password)
              }
              errorMessage={formik1.touched.password && formik1.errors.password}
              wrapperClass="mt-4"
              fullwidth={true}
            />
            <div>
              <button
                type="submit"
                className={`btn nowrap w-100 mt-4 btn-primary login-section-btn`}
                disabled={isLoading}
              >
                {isLoading ? "CHECKING" : "CHECK"}
              </button>
            </div>
            <h6
              className={`mb-4 mt-4 cursor-pointer`}
              onClick={handleModalOpen}
            >
              CAN'T SIGN IN
              <br />
              CONTACT THE TECHNOLOGY TEAM
            </h6>
          </form>
        </>
      ) : (
        <>
          <h2 className={`logo-txt w-700 mb-4 mt-3`}>
            DOUBLE AUTHENTICATION SIGN IN
          </h2>
          <form onSubmit={formik2.handleSubmit}>
            <PhoneInput
              label="PHONE NUMBER"
              dom={{
                name: "phone",
                onChange: (e) => {
                  formik2.setFieldValue("phone", e.target.value, true);
                },
                value: formik2.values.phone,
                placeholder: "PHONE NO.",
              }}
              error={formik2.touched.phone && Boolean(formik2.errors.phone)}
              errorMessage={formik2.touched.phone && formik2.errors.phone}
              countryCode={countryCode}
              options={codesArr}
              inputChange={(e) => setCountryCode(e.target.value)}
              fullwidth={true}
            />
            <FormInput
              label="EMAIL ID"
              dom={{
                type: "email",
                name: "email",
                onChange: (e) => {
                  formik2.setFieldValue("email", e.target.value, true);
                },
                value: formik2.values.email,
                placeholder: "EMAIL",
              }}
              error={formik2.touched.email && Boolean(formik2.errors.email)}
              errorMessage={formik2.touched.email && formik2.errors.email}
              wrapperClass="mt-4"
              fullwidth={true}
            />
            <div
              className={`${
                otpId || isLoading ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <button
                type="submit"
                className={`btn nowrap w-100 btn-primary mt-4 login-section-btn`}
                disabled={otpId || isLoading}
              >
                {isLoading ? "SENDING OTP" : "GENERATE"}
              </button>
            </div>
          </form>
          {otpId && (
            <>
              <div className={`d-grid ${styles.twoCol}`}>
                <form onSubmit={formik3.handleSubmit}>
                  <FormInput
                    label="PHONE OTP"
                    dom={{
                      type: "text",
                      name: "potp",
                      onChange: async (e) => {
                        formik3.setFieldValue("potp", e.target.value, true);
                        e.target.value.length === 6 &&
                          callFun(e.target.value, "phone");
                      },
                      value: formik3.values.potp,
                      placeholder: "PHONE OTP",
                    }}
                    postFix={() =>
                      otpPhoneVerificationSuccess ? (
                        <AiOutlineCheckCircle className={styles.greenIcon} />
                      ) : (
                        otpPhoneVerificationError && (
                          <RiErrorWarningLine className={styles.redIcon} />
                        )
                      )
                    }
                    error={formik3.touched.potp && Boolean(formik3.errors.potp)}
                    errorMessage={formik3.touched.potp && formik3.errors.potp}
                    wrapperClass="mt-4 mr-3"
                    fullwidth={true}
                  />
                </form>
                <form onSubmit={formik4.handleSubmit}>
                  <FormInput
                    label="EMAIL OTP"
                    dom={{
                      type: "text",
                      name: "eotp",
                      onChange: (e) => {
                        formik4.setFieldValue("eotp", e.target.value, true);
                        e.target.value.length === 6 &&
                          callFun(e.target.value, "email");
                      },
                      value: formik4.values.eotp,
                      placeholder: "EMAIL OTP",
                    }}
                    postFix={() =>
                      otpEmailVerificationSuccess ? (
                        <AiOutlineCheckCircle className={styles.greenIcon} />
                      ) : (
                        otpEmailVerificationError && (
                          <RiErrorWarningLine className={styles.redIcon} />
                        )
                      )
                    }
                    error={formik4.touched.eotp && Boolean(formik4.errors.eotp)}
                    errorMessage={formik4.touched.eotp && formik4.errors.eotp}
                    wrapperClass="mt-4"
                    fullwidth={true}
                  />
                </form>
              </div>
              <p className="mt-4">
                OTP WILL EXPIRE IN: <span id="_timer">02:00</span>
              </p>
              {isSuccess &&
                formik3.values.potp.length === 6 &&
                formik4.values.eotp.length === 6 && (
                  <button
                    type="submit"
                    className={`btn nowrap w-100 btn-primary mt-4 login-section-btn`}
                    disabled={!isSuccess}
                    onClick={() => navigate("/")}
                  >
                    ENTER
                  </button>
                )}
            </>
          )}
          <h6
            className={`mb-4 mt-4 cursor-pointer`}
            onClick={() => setSecPassword(!secPassword)}
          >
            USE MY SECURITY PASSWORD
          </h6>
        </>
      )}
    </div>
  );
};

export default Login;
