import React from "react";
import { JobContainer } from "../../components";

const AllJob = () => {
  return (
    <div>
      {/* <Search /> */}
      <JobContainer />
    </div>
  );
};

export default AllJob;
