import React, { useEffect, useState } from "react";
import styles from "./leads.module.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  httpGetAllLeads,
  httpUpdateLead,
} from "../../features/leads/leadsSlice";
import { toast } from "react-toastify";
import { FormSelect } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "rc-tooltip";
import { openDialog } from "../../features/dialog/dialogSlice";
import moment from "moment";
import { allLeadsSubject } from "../../utils/globalVars";
import * as deepcopy from "deepcopy";
import { Spinner } from "reactstrap";

const statusOptions = [
  {
    label: "To Reply",
    value: 1,
  },
  {
    label: "In Conversation",
    value: 2,
  },
  {
    label: "Success",
    value: 3,
  },
  {
    label: "Unsuccessful",
    value: 4,
  },
];

const sortOptions = [
  {
    label: "Latest",
    value: "latest",
  },
  {
    label: "Oldest",
    value: "oldest",
  },
  ...statusOptions,
];

function LeadsContainer() {
  const dispatch = useDispatch();
  const [sort, setSort] = useState("latest");
  const [leadsData, setLeadsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    allLeadsSubject.subscribe(() => getAllLeadsData());
    getAllLeadsData();
  }, []);

  const getAllLeadsData = () => {
    setIsLoading(true);
    const query = isNaN(sort) ? `?sortBy=${sort}` : `?filter=${sort}`;
    dispatch(httpGetAllLeads({ query }))
      .then((res) => {
        const leadsArr = res?.payload?.leads;
        setLeadsData(leadsArr);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res?.payload);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    sort && getAllLeadsData();
  }, [sort]);

  const handleSelectChange = (e, i) => {
    const temp = [...leadsData];
    if (e) temp[i].status = e.value;
    setLeadsData(temp);

    dispatch(httpUpdateLead(temp[i]))
      .then((res) => {
        toast.success(res?.payload);
        getAllLeadsData();
      })
      .catch((res) => toast.error(res?.payload));
  };

  const handleAdminNotes = (notes, index) => {
    dispatch(
      openDialog({
        data: {
          footer: true,
          cancelBtn: true,
          title: "Admin Notes",
          titleClass: "fs20",
          buttonText: {
            submit: "Save",
          },
          notes,
          leadsData,
          index,
          onSubmit: async (e, closeModal, modalData) => {
            const temp = deepcopy([...leadsData]);
            const index = modalData.data.index;
            if (index > -1) {
              temp[index].noteByAdmin = modalData.data.notes;
              dispatch(httpUpdateLead(temp[index]))
                .then((res) => {
                  toast.success(res?.payload);
                  allLeadsSubject.next();
                })
                .catch((res) => toast.error(res?.payload));
            }
            closeModal();
          },
        },
        path: `LeadsNotes`,
      })
    );
  };

  const handleCustNotes = (notes) => {
    dispatch(
      openDialog({
        body: notes ? notes : "N/A",
        data: {
          closeButton: false,
          footer: true,
          confModal: true,
          title: "Customer Notes",
          cancelBtn: true,
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column  overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Leads</h4>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <Link to="/leads-email">
                <button className="btn btn-primary mx-3">Email IDs</button>
              </Link>
            </div>
            <div>
              <FormSelect
                name="sort-dropdown"
                className={styles.formSelect}
                onChange={(e) => {
                  if (e) setSort(e?.value);
                }}
                value={{
                  label: sort,
                  value: sort,
                }}
                fullwidth={true}
                options={sortOptions}
                wrapperClass="w-250"
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            {isLoading && (
              <div className="h-100 d-flex align-items-center justify-content-center">
                <Spinner className="m-1" color="info" />
              </div>
            )}
            {!isLoading && leadsData && leadsData?.length ? (
              <div className={`d-flex flex-column`}>
                {leadsData?.map((dt, i) => (
                  <div className={`${styles.leads} mt-4`} key={i}>
                    <div className={"d-flex flex-column"}>
                      <p className={`mb-0 w-600 ${styles.leadsDetail}`}>
                        Leads # {dt?._id} -{" "}
                        {moment.unix(dt?.createdAT).format("DD MMM, YYYY")}
                      </p>
                      <p className={`mb-0 w-600`}>
                        {dt?.fName} {dt?.lname}
                      </p>
                      <p className={`mb-0 w-600 ${styles.leadsDetail}`}>
                        {dt?.designation}, {dt?.company}
                      </p>
                      <div className="d-flex">
                        <p className={`mb-0 w-600 ${styles.leadsDetail}`}>
                          Ph. -
                        </p>
                        <p className={`mb-0 w-600`}>&nbsp;{dt?.mobile}</p>
                      </div>
                      <div className="d-flex">
                        <p className={`mb-0 w-600 ${styles.leadsDetail}`}>
                          E -
                        </p>
                        <p className={`mb-0 w-600`}>&nbsp;{dt?.email}</p>
                      </div>
                    </div>
                    <div>
                      <Tooltip placement="bottom" overlay={"Admin Notes"}>
                        <FontAwesomeIcon
                          className={styles.icon}
                          icon="fa fa-file-text"
                          color={dt?.noteByAdmin ? "#57a957" : "#a6a6a6"}
                          onClick={() => handleAdminNotes(dt?.noteByAdmin, i)}
                        />
                      </Tooltip>

                      <Tooltip placement="bottom" overlay={"Customer Notes"}>
                        <FontAwesomeIcon
                          className={styles.icon}
                          icon="fa fa-file-text"
                          color={dt?.noteByCust ? "#57a957" : "#a6a6a6"}
                          onClick={() => handleCustNotes(dt?.noteByCust)}
                        />
                      </Tooltip>
                    </div>
                    <div>
                      <FormSelect
                        name="sort-dropdown"
                        className={styles.formSelect}
                        onChange={(e) => handleSelectChange(e, i)}
                        value={{
                          label: dt?.status,
                          value: dt?.status,
                        }}
                        fullwidth={true}
                        options={statusOptions}
                        wrapperClass="w-250"
                        isClearable={false}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !isLoading && (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <h3>No Data Found</h3>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LeadsContainer;
