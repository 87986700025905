import React, { useEffect } from "react";
import Select from "./select";

const PhoneInput = (props) => {
  const {
    dom = {},
    label = "",
    error = "",
    errorMessage,
    errorClass = "",
    inputChange,
    countryCode,
    wrapperClass = "",
    extraContent,
    fullwidth = false,
    options,
  } = props;

  const inputRef = React.createRef();
  useEffect(() => {
    dom.autoFocus && inputRef?.current?.focus();
  }, [dom.autoFocus, inputRef]);

  return (
    <div className={`${wrapperClass ? wrapperClass : ""}`}>
      {label && (
        <div className="px-0">
          <label htmlFor="email">{label}</label>
        </div>
      )}
      <div className="d-flex align-items-center">
        <div className="d-flex w-100 position-relative">
          <Select
            name="countryCode"
            value={countryCode}
            inputChange={inputChange}
            error={error}
            options={options}
          />
          <input
            {...dom}
            type="phone"
            ref={inputRef}
            autoComplete="off"
            className={`form-control phone-no ${dom.className} ${
              error ? "form-control-error" : ""
            } ${fullwidth ? "w-100" : ""}`}
          />
        </div>
        {extraContent && extraContent()}
      </div>
      {error ? (
        <div className="input-error-message">
          <span className={`${errorClass}`}>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default PhoneInput;
