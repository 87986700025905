import * as yup from "yup";

export const validationSchema1 = yup.object({
  email: yup
    .string("ENTER EMAIL")
    .email("ENTER A VALID EMAIL")
    .required("EMAIL IS REQUIRED"),
  password: yup.string("ENTER PASSWORD").required("PASSWORD IS REQUIRED"),
});

export const validationSchema2 = yup.object({
  phone: yup
    .string("ENTER PHONE NO.")
    .matches(/^[0-9]+$/, "ONLY NUMBERS ARE ALLOWED FOR THE PHONE NO. FIELD")
    .min(10, "PHONE NUMBER SHOULD BE 10 CHARACTERS IN LENGTH!")
    .max(10, "PHONE NUMBER SHOULD BE 10 CHARACTERS IN LENGTH!")
    .required("PHONE NUMBER IS REQUIRED"),
  email: yup
    .string("ENTER EMAIL")
    .email("ENTER A VALID EMAIL")
    .required("EMAIL IS REQUIRED"),
});

export const validationSchema3 = yup.object({
  potp: yup
    .string("ENTER PHONE OTP")
    .min(6, "OTP SHOULD BE 6 DIGIT IN LENGTH!")
    .max(6, "OTP SHOULD BE 6 DIGIT IN LENGTH!")
    .required("OTP IS REQUIRED"),
});

export const validationSchema4 = yup.object({
  eotp: yup
    .string("ENTER EMAIL OTP")
    .min(6, "OTP SHOULD BE 6 DIGIT IN LENGTH!")
    .max(6, "OTP SHOULD BE 6 DIGIT IN LENGTH!")
    .required("OTP IS REQUIRED"),
});

export const validationSchema5 = yup.object({
  note: yup.string("ENTER NOTE").required("NOTE IS REQUIRED"),
  phone: yup
    .string("ENTER PHONE NO.")
    .matches(/^[0-9]+$/, "ONLY NUMBERS ARE ALLOWED FOR THE PHONE NO. FIELD")
    .min(10, "PHONE NUMBER SHOULD BE 10 CHARACTERS IN LENGTH!")
    .max(10, "PHONE NUMBER SHOULD BE 10 CHARACTERS IN LENGTH!")
    .required("PHONE NUMBER IS REQUIRED"),
  email: yup
    .string("ENTER EMAIL")
    .email("ENTER A VALID EMAIL")
    .required("EMAIL IS REQUIRED"),
});
