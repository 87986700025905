import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAllLinksThunk, getAllHomePageData } from "./linksThunk";

const initialState = {
  isLoading: false,
  links: null,
  homePage: {},
};

export const httpGetAllLinks = createAsyncThunk(
  "links/getAllLinks",
  getAllLinksThunk
);

export const httpGetHomePageData = createAsyncThunk(
  "links/getHomePageData",
  getAllHomePageData
);

const linksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllLinks.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllLinks.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.links = payload.links;
    },
    [httpGetAllLinks.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpGetHomePageData.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetHomePageData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.homePage = payload.homePage;
    },
    [httpGetHomePageData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = linksSlice.actions;
export default linksSlice.reducer;
