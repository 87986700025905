import React from "react";

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      options: [],
    };
    this.wrapperRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options });
    }
  }

  toggleDropDown = (flag = true) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        open: flag,
        options: this.state.options,
      };
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.toggleDropDown(false);
    }
  };

  render() {
    const {
      value = "",
      name,
      inputChange,
      error,
      fullwidth = false,
    } = this.props;

    return (
      <div
        className={`select-box custom-phone-input position-absolute`}
        ref={this.wrapperRef}
        style={{ width: "75px" }}
      >
        <div
          className={`form-control ${error ? "form-control-error" : ""} ${
            fullwidth ? "w-100" : ""
          } value-container cursor-pointer `}
          onClick={() => {
            this.toggleDropDown(true);
          }}
        >
          {value}
          <span className={`dropdown-carret`}>{/* <AiFillCaretDown /> */}</span>
        </div>
        {this.state.open && (
          <div className={`dropdown-container d-flex flex-column`}>
            <div>
              <input
                onChange={(e) => {
                  const value = e.target.value;
                  const filterdOptions =
                    this.props.options && this.props.options.length > 0
                      ? this.props.options.filter((option) => {
                          return (
                            option.name.includes(value) ||
                            option.dial_code.includes(value)
                          );
                        })
                      : [];

                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      options: value ? filterdOptions : this.props.options,
                    };
                  });
                }}
                placeholder="Search Country"
                className={`form-control w-100 border-radius-0`}
                type="text"
              ></input>
            </div>
            <div className={`h-100 overflow-auto cursor-pointer`}>
              {this.state.options &&
                this.state.options.map((option) => {
                  return (
                    <div
                      key={option.id}
                      className={`d-flex justify-content-between`}
                      onClick={() => {
                        inputChange({
                          target: {
                            value: option.dial_code,
                            name: name,
                          },
                        });
                        this.toggleDropDown(false);
                      }}
                    >
                      <div>{option.dial_code}</div>
                      <div>{option.name}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Select;
