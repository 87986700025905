import { hideLoading, httpGetAllJob, showLoading } from "../allJob/allJobSlice";
import IncredAPI, {
  checkForUnauthorizedResponse,
} from "./../../utils/IncredAPI";

export const addJobThunk = async (job, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/job", job);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteJobThunk = async (jobId, thunkAPI) => {
  try {
    thunkAPI.dispatch(showLoading());
    const response = await IncredAPI.delete(`/job/${jobId}`);
    thunkAPI.dispatch(hideLoading());
    thunkAPI.dispatch(httpGetAllJob());
    return response.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const editJobThunk = async ({ jobId, updatedData }, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/job/${jobId}`, updatedData);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
