import React from "react";
import Login from "../auth/Login";
import styles from "./landing.module.css";
// import quorumVideo from "./../../assets/bg.mp4";

const Landing = () => {
  return (
    <div className={styles.landingContainer}>
      <section className={styles.landingContainer_video} />
      <section className={styles.landingContainer_login}>
        <Login />
      </section>
    </div>
  );
};

export default Landing;
