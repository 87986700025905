import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllProductCategoryThunk = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/product/categories/`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const addProductCategoryThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/product/category", data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateProductCategoryThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(
      `/product/category/${data?._id}`,
      data
    );
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteProductCategoryThunk = async (productId, thunkAPI) => {
  try {
    const response = await IncredAPI.delete(`/product/category/${productId}`);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
