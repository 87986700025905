import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import styles from "./orders.module.css";
import { toast } from "react-toastify";
import moment from "moment";
// import { HiPrinter } from "react-icons/hi";
import { httpViewOrder } from "../../features/orders/ordersSlice";
import { Spinner } from "reactstrap";
// import { exportToCsv } from "../../utils/globalVars";

// const keymapperTransacation = {
//   header: [
//     "Customer Name",
//     "EmailId",
//     "Phone No.",
//     "Address",
//     "Note",
//     "Order Date",
//     "Total Items",
//     "Status",
//   ],
//   keys: [
//     "name",
//     "emailId",
//     "phoneNumber",
//     ["street", "city", "state", "country", "areaCode"],
//     "note",
//     "orderId",
//     "orderDate",
//     "totalItems",
//     "orderStatus",
//   ],
//   mapper: {
//     orderStatus: {
//       1: "new order - pay later",
//       2: "new order - stripe paid",
//       3: "processing",
//       4: "shipped",
//       5: "in conversation",
//       6: "refunded",
//     },
//   },
// };

const ViewOrderContainer = () => {
  const dispatch = useDispatch();
  const { oId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    if (oId) {
      setIsLoading(true);
      dispatch(httpViewOrder(oId))
        .then((res) => {
          const objData = res?.payload?.order;
          setOrderData(objData);
          setIsLoading(false);
        })
        .catch((res) => {
          toast.error(res?.payload);
          setIsLoading(false);
        });
    }
  }, [oId]);

  // const handleDownload = () => {
  //   const arr =
  //     Object.keys(orderData).length > 0
  //       ? [
  //           {
  //             ...orderData,
  //             ...orderData?.customer,
  //             orderId: orderData?._id,
  //             orderDate: moment
  //               .unix(orderData?.dateTime)
  //               .format("DD MMM, YYYY"),
  //             totalItems: orderData?.items?.length || 0,
  //             orderStatus: orderData?.orderStatus || "",
  //           },
  //         ]
  //       : [];
  //   exportToCsv(
  //     arr,
  //     "Orders_Data" + moment().format("YYYY-MM-DD_HH.mm"),
  //     keymapperTransacation
  //   );
  // };

  const checkPrice = (val) => {
    return val || val === 0 ? (val === 0 ? "free" : `${val} USD`) : "";
  };

  return (
    <React.Fragment>
      <div
        className={`${styles.orderContainer} h-100 d-flex flex-column  overflow-hidden`}
      >
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="page-title mb-0">View Order</h4>
            <p className={`mb-0 w-600 ${styles.ordersDetail}`}>
              orders # {orderData?._id} -{" "}
              {moment.unix(orderData?.dateTime).format("DD MMM, YYYY")}
            </p>
            <p className={`mb-0 w-600 ${styles.ordersDetail}`}>
              To Ship - {orderData?.items?.length} items
            </p>
            <p className={`mb-0 w-600 ${styles.ordersDetail}`}>
              Note - {orderData?.customer?.note}
            </p>
          </div>
          <div>
            <div
              className={`${styles.text1} container d-flex justify-content-end`}
            >
              <div className="total-section">
                <div>order summary</div>
                {/* <div>subtotal - {}</div>
                <div>shipping cost - {}</div>
                <div>sales text - {}</div> */}
                <div>
                  estimated total - {orderData?.bill?.estimatedTotal} USD
                </div>
                {orderData?.couponCode?.giftCode && (
                  <div>Gift code - {orderData?.couponCode?.giftCode}</div>
                )}
                {orderData?.couponCode?.retailCode && (
                  <div>Retail code - {orderData?.couponCode?.retailCode}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.custmorDetl}>
            <div className={`text-end`}>
              <p className={`mb-0 ${styles.text1}`}>
                {orderData?.customer?.name}
              </p>
              <div className="">
                <p className="mb-0">
                  <span className={`${styles.ordersDetail}`}> Ph.</span> -{" "}
                  <span className={`${styles.text1}`}>
                    {orderData?.customer?.phoneNumber}
                  </span>
                </p>
              </div>
              <div className="">
                <p className="mb-0">
                  <span className={`${styles.ordersDetail}`}> E </span>-{" "}
                  <span className={`${styles.text1}`}>
                    {orderData?.customer?.emailId}
                  </span>
                </p>
              </div>
              <div className="">
                <p className="mb-0">
                  <span className={`${styles.ordersDetail}`}> A </span>-{" "}
                  <span className={`${styles.text1}`}>
                    {`${orderData?.customer?.street},
                      ${orderData?.customer?.city},
                      ${orderData?.customer?.state},
                      ${orderData?.customer?.country},
                      ${orderData?.customer?.areaCode}`}
                  </span>
                </p>
              </div>
            </div>
            {/* <HiPrinter className={styles.printIcon} onClick={handleDownload} /> */}
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div
            className={`${styles.viewordersCard} card-body h-100 overflow-auto`}
          >
            {isLoading && (
              <div>
                <Spinner className="m-1" color="info" />
              </div>
            )}
            {!isLoading &&
              orderData?.items?.map((data, index) => {
                return (
                  <div className={styles.productDetail} key={index}>
                    <div>
                      <div
                        className={`${styles.mb5} w-600 ${styles.ordersDetail}`}
                      >
                        item {index + 1} - {data?.productId?.name}
                      </div>
                    </div>
                    <div>
                      <div className="product-sec">
                        <div className="">
                          <div className={`p1-sec ${styles.mb5}`}>
                            {data?.details?.p1 && (
                              <div className={`${styles.text1}`}>
                                {data?.details?.p1?.title} -{" "}
                                <span> {data?.details?.p1?.price} USD </span>
                              </div>
                            )}
                            <div className={styles.ordersDetail}>
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec4 && (
                                  <div>
                                    personalize - {data?.details?.spec4}
                                  </div>
                                )}
                              {data?.details?.hand && (
                                <div>hand - {data?.details?.hand}</div>
                              )}
                              {data?.details?.spec1 && (
                                <div>Length - {data?.details?.spec1}</div>
                              )}
                              {data?.details?.spec2 && (
                                <div className={styles.prodDtlContainer}>
                                  <div>grip - {data?.details?.spec2?.name}</div>
                                  <div>
                                    {checkPrice(data?.details?.spec2?.price)}
                                  </div>
                                </div>
                              )}
                              {data?.details?.spec3 && (
                                <div className={styles.prodDtlContainer}>
                                  <div>
                                    shaft - {data?.details?.spec3?.name}
                                  </div>
                                  <div>
                                    {checkPrice(data?.details?.spec3?.price)}
                                  </div>
                                </div>
                              )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec5 && (
                                  <div>Lie Angle - {data?.details?.spec5}</div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec6 && (
                                  <div>Face Angle - {data?.details?.spec6}</div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec7 && (
                                  <div>
                                    Head Weight - {data?.details?.spec7}
                                  </div>
                                )}
                              {data?.details?.uiId !== "2" &&
                                data?.details?.uiId !== 2 &&
                                data?.details?.spec14 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      Swing Weight -{" "}
                                      {data?.details?.spec14?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec14?.price)}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className={`p2-sec ${styles.mb5}`}>
                            {data?.details?.p2 && (
                              <div className={`${styles.text1}`}>
                                {data?.details?.p2?.title} -{" "}
                                <span> {data?.details?.p2?.price} USD </span>
                              </div>
                            )}
                            <div className={styles.ordersDetail}>
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec14 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      Swing Weight -{" "}
                                      {data?.details?.spec14?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec14?.price)}
                                    </div>
                                  </div>
                                )}
                              {data?.details?.uiId !== "2" &&
                                data?.details?.uiId !== 2 &&
                                data?.details?.spec4 && (
                                  <div>
                                    personalize - {data?.details?.spec4}
                                  </div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec8 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      finish - {data?.details?.spec8?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec8?.price)}
                                    </div>
                                  </div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec9 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      face insert - {data?.details?.spec9?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec9?.price)}
                                    </div>
                                  </div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec10 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      face grooves -{" "}
                                      {data?.details?.spec10?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec10?.price)}
                                    </div>
                                  </div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec11 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      top markings -{" "}
                                      {data?.details?.spec11?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec11?.price)}
                                    </div>
                                  </div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec12 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      paint fill - {data?.details?.spec12?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec12?.price)}
                                    </div>
                                  </div>
                                )}
                              {(data?.details?.uiId === "2" ||
                                data?.details?.uiId === 2) &&
                                data?.details?.spec13 && (
                                  <div className={styles.prodDtlContainer}>
                                    <div>
                                      headcover - {data?.details?.spec13?.name}
                                    </div>
                                    <div>
                                      {checkPrice(data?.details?.spec13?.price)}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={` ${styles.text1} text-end`}>
                        Total - {data?.totalPrice} USD
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewOrderContainer;
