import React, { useEffect, useState } from "react";
import styles from "./products.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  httpGetAllProductCategory,
  httpAddProductCategory,
  httpUpdateProductCategory,
  httpDeleteProductCategory,
} from "../../features/product-category/productCategorySlice";
import { toast } from "react-toastify";
import { openDialog } from "../../features/dialog/dialogSlice";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { TiPlus } from "react-icons/ti";
import { Spinner } from "reactstrap";
import {
  httpGetAllProductType,
  httpAddProductType,
  httpUpdateProductType,
  httpDeleteProductType,
} from "../../features/product-type/productTypeSlice";

function ProductsSettingContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [catData, setCatData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pTypeData, setPTypeData] = useState([]);
  const [isPLoading, setIsPLoading] = useState(false);
  const [activeCat, setActiveCat] = useState(0);
  const [activeSubCat, setActiveSubCat] = useState(0);

  useEffect(() => {
    getAllCategoryData();
    getAllProductTypeData();
  }, []);

  const getAllCategoryData = () => {
    setIsLoading(true);
    dispatch(httpGetAllProductCategory())
      .then((res) => {
        const catArr = res?.payload?.categories;
        setCatData(catArr);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res?.payload);
        setIsLoading(false);
      });
  };

  const getAllProductTypeData = () => {
    setIsPLoading(true);
    dispatch(httpGetAllProductType())
      .then((res) => {
        const catArr = res?.payload?.productTypes;
        setPTypeData(catArr);
        setIsPLoading(false);
      })
      .catch((res) => {
        toast.error(res?.payload);
        setIsPLoading(false);
      });
  };

  const handleAddCategory = (e, title, data, pId) => {
    e.stopPropagation();
    dispatch(
      openDialog({
        data: {
          footer: true,
          cancelBtn: true,
          title,
          titleClass: "fs20",
          buttonText: {
            submit: "Save",
          },
          name: data?.name || "",
          onSubmit: async (e, closeModal, modalData) => {
            const obj = {
              name: modalData?.data,
              parentId: pId,
              _id: data?._id,
            };
            if (data?._id) {
              !pId && delete obj.parentId;
              dispatch(httpUpdateProductCategory(obj))
                .then((res) => {
                  toast.success(res?.payload);
                  getAllCategoryData();
                })
                .catch((res) => toast.error(res?.payload));
            } else {
              !pId && delete obj.parentId;
              !data?._id && delete obj._id;
              dispatch(httpAddProductCategory(obj))
                .then((res) => {
                  toast.success(res?.payload);
                  getAllCategoryData();
                })
                .catch((res) => toast.error(res?.payload));
            }
            closeModal();
          },
        },
        path: `AddCategory`,
      })
    );
  };

  const handleDeleteCategory = (e, title, data) => {
    e.stopPropagation();
    dispatch(
      openDialog({
        body: `Do you really want to delete this category?`,
        data: {
          closeButton: false,
          footer: true,
          confModal: true,
          title,
          buttonText: {
            cancel: "No",
            submit: "Yes",
            cancelClass: "btn-danger",
          },
          cancelBtn: true,
          onSubmit: (e, closeModal) => {
            const deleteId = data?._id;
            dispatch(httpDeleteProductCategory(deleteId))
              .then((res) => {
                toast.success(res?.payload);
                getAllCategoryData();
              })
              .catch((res) => toast.error(res?.payload));
            closeModal();
          },
        },
      })
    );
  };

  const handleAddProductType = (e, data) => {
    dispatch(
      openDialog({
        data: {
          footer: true,
          cancelBtn: true,
          title: data?._id ? "Edit Product Type" : "Add Product Type",
          titleClass: "fs20",
          buttonText: {
            submit: "Save",
          },
          name: data?.name || "",
          onSubmit: async (e, closeModal, modalData) => {
            const obj = {
              name: modalData?.data,
              _id: data?._id,
            };
            if (data?._id) {
              dispatch(httpUpdateProductType(obj))
                .then((res) => {
                  toast.success(res?.payload);
                  getAllProductTypeData();
                })
                .catch((res) => toast.error(res?.payload));
            } else {
              !data?._id && delete obj._id;
              dispatch(httpAddProductType(obj))
                .then((res) => {
                  toast.success(res?.payload);
                  getAllProductTypeData();
                })
                .catch((res) => toast.error(res?.payload));
            }
            closeModal();
          },
        },
        path: `AddCategory`,
      })
    );
  };

  const handleDeleteProductType = (e, id) => {
    dispatch(
      openDialog({
        body: `Do you really want to delete this product type?`,
        data: {
          closeButton: false,
          footer: true,
          confModal: true,
          title: "Delete Product Type",
          buttonText: {
            cancel: "No",
            submit: "Yes",
            cancelClass: "btn-danger",
          },
          cancelBtn: true,
          onSubmit: (e, closeModal) => {
            const deleteId = id;
            dispatch(httpDeleteProductType(deleteId))
              .then((res) => {
                toast.success(res?.payload);
                getAllProductTypeData();
              })
              .catch((res) => toast.error(res?.payload));
            closeModal();
          },
        },
      })
    );
  };

  const handleCatClick = (i) => {
    setActiveCat(i);
    setActiveSubCat(0);
  };

  const handleSubCatClick = (i) => {
    setActiveSubCat(i);
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Product settings</h4>
          </div>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary mx-3"
              onClick={() => navigate("/products")}
            >
              Back
            </button>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            <div className={styles.productSettings}>
              <div>
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <div>
                    <h5 className={styles.fontBlack}>Category</h5>
                  </div>
                  <div>
                    <TiPlus
                      className={`${styles.settingIcon} ml-3`}
                      onClick={(e) => handleAddCategory(e, "Add Category")}
                    />
                  </div>
                </div>
                <ul className="p-0">
                  {isLoading && (
                    <div>
                      <Spinner className="m-1" color="info" />
                    </div>
                  )}
                  {!isLoading && catData && catData?.length > 0 ? (
                    catData?.map((data, i) => (
                      <li
                        className={`d-flex justify-content-between align-items-center cursor-pointer mb-2 p-2 ${
                          activeCat === i ? styles.activeTab : ""
                        }`}
                        onClick={() => handleCatClick(i)}
                        key={i}
                      >
                        <h6 className={`mb-0 ${styles.fontBlack}`}>
                          {data?.name}
                        </h6>
                        <div>
                          <FaEdit
                            className={`${styles.settingIcon} ml-3`}
                            onClick={(e) =>
                              handleAddCategory(e, "Edit Category", data)
                            }
                          />
                          <AiFillDelete
                            className={`${styles.settingIcon} ml-3`}
                            onClick={(e) =>
                              handleDeleteCategory(e, "Delete Category", data)
                            }
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No Data Found</p>
                  )}
                </ul>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <div>
                    <h5 className={styles.fontBlack}>Sub Category</h5>
                  </div>
                  <div>
                    <TiPlus
                      className={`${styles.settingIcon} ml-3`}
                      onClick={(e) =>
                        handleAddCategory(
                          e,
                          "Add Sub Category",
                          {},
                          catData?.[activeCat]?._id
                        )
                      }
                    />
                  </div>
                </div>
                <ul className="p-0">
                  {isLoading && (
                    <div>
                      <Spinner className="m-1" color="info" />
                    </div>
                  )}
                  {!isLoading &&
                  catData &&
                  catData?.length > 0 &&
                  catData?.[activeCat]?.children &&
                  catData?.[activeCat]?.children?.length > 0 ? (
                    catData?.[activeCat]?.children?.map((data, i) => (
                      <li
                        className={`d-flex justify-content-between align-items-center cursor-pointer mb-2 p-2 ${
                          activeSubCat === i ? styles.activeTab : ""
                        }`}
                        onClick={() => handleSubCatClick(i)}
                        key={i}
                      >
                        <h6 className={`mb-0 ${styles.fontBlack}`}>
                          {data?.name}
                        </h6>
                        <div>
                          <FaEdit
                            className={`${styles.settingIcon} ml-3`}
                            onClick={(e) =>
                              handleAddCategory(
                                e,
                                "Edit Sub Category",
                                data,
                                catData?.[activeCat]?._id
                              )
                            }
                          />
                          <AiFillDelete
                            className={`${styles.settingIcon} ml-3`}
                            onClick={(e) =>
                              handleDeleteCategory(
                                e,
                                "Delete Sub Category",
                                data
                              )
                            }
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No Data Found</p>
                  )}
                </ul>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <div>
                    <h5 className={styles.fontBlack}>Sub Sub Category</h5>
                  </div>
                  <div>
                    <TiPlus
                      className={`${styles.settingIcon} ml-3`}
                      onClick={(e) =>
                        handleAddCategory(
                          e,
                          "Add Sub Sub Category",
                          {},
                          catData?.[activeCat]?.children?.[activeSubCat]?._id
                        )
                      }
                    />
                  </div>
                </div>
                <ul className="p-0">
                  {isLoading && (
                    <div>
                      <Spinner className="m-1" color="info" />
                    </div>
                  )}
                  {!isLoading &&
                  catData &&
                  catData?.length > 0 &&
                  catData?.[activeCat]?.children &&
                  catData?.[activeCat]?.children?.length > 0 &&
                  catData?.[activeCat]?.children?.[activeSubCat]?.children &&
                  catData?.[activeCat]?.children?.[activeSubCat]?.children
                    ?.length > 0 ? (
                    catData[activeCat].children[activeSubCat].children?.map(
                      (data, i) => (
                        <li
                          className={`d-flex justify-content-between align-items-center mb-2 p-2`}
                          key={i}
                        >
                          <h6 className={`mb-0 ${styles.fontBlack}`}>
                            {data?.name}
                          </h6>
                          <div>
                            <FaEdit
                              className={`${styles.settingIcon} ml-3`}
                              onClick={(e) =>
                                handleAddCategory(
                                  e,
                                  "Edit Sub Sub Category",
                                  data,
                                  catData?.[activeCat]?.children?.[activeSubCat]
                                    ?._id
                                )
                              }
                            />
                            <AiFillDelete
                              className={`${styles.settingIcon} ml-3`}
                              onClick={(e) =>
                                handleDeleteCategory(
                                  e,
                                  "Delete Sub Sub Category",
                                  data
                                )
                              }
                            />
                          </div>
                        </li>
                      )
                    )
                  ) : (
                    <p>No Data Found</p>
                  )}
                </ul>
              </div>
            </div>
            <div className={styles.productSettings}>
              <div>
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <div>
                    <h5 className={styles.fontBlack}>Product Types</h5>
                  </div>
                  <div>
                    <TiPlus
                      className={`${styles.settingIcon} ml-3`}
                      onClick={(e) => handleAddProductType(e)}
                    />
                  </div>
                </div>
                <ul className="p-0">
                  {isPLoading && (
                    <div>
                      <Spinner className="m-1" color="info" />
                    </div>
                  )}
                  {!isPLoading && pTypeData && pTypeData?.length > 0 ? (
                    pTypeData?.map((data, i) => (
                      <li
                        className={`d-flex justify-content-between align-items-center mb-2 p-2`}
                        key={i}
                      >
                        <h6 className={`mb-0 ${styles.fontBlack}`}>
                          {data?.name}
                        </h6>
                        <div>
                          <AiFillDelete
                            className={`${styles.settingIcon} ml-3`}
                            onClick={(e) =>
                              handleDeleteProductType(e, data?._id)
                            }
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No Data Found</p>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductsSettingContainer;
