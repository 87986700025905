import React, { useCallback, useEffect, useState } from "react";
import styles from "./products.module.css";
import { useDispatch } from "react-redux";
import {
  httpGetAllProducts,
  httpDeleteProducts,
} from "../../features/products/productsSlice";
import { toast } from "react-toastify";
import { FormInput } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openDialog } from "../../features/dialog/dialogSlice";
import { useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import Pagination from "../../components/pagination/pagination";
import { debounce } from "debounce";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { Spinner } from "reactstrap";

function ProductsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(undefined);

  useEffect(() => {
    getAllProductsData(search);
  }, [page]);

  useEffect(() => {
    if (search?.length === 0 || search?.length > 3) getSearchDebounce(search);
  }, [search]);

  const getSearchDebounce = useCallback(
    debounce((search) => {
      getAllProductsData(search);
    }, 1000),
    []
  );

  const getAllProductsData = (search1) => {
    setIsLoading(true);
    const query = `?page=${page}&limit=40&searchKeyword=${search1 || ""}`;
    dispatch(httpGetAllProducts({ query }))
      .then((res) => {
        const productsArr = res?.payload?.products;
        setProductsData(productsArr);
        setTotalCount(res?.payload?.total_rec);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res?.payload);
        setIsLoading(false);
      });
  };

  const handleAddProduct = () => {
    dispatch(
      openDialog({
        data: {
          footer: false,
          title: "Add a New Product",
          titleClass: "fs20",
          // className: "section-list",
        },
        path: "AddProduct",
      })
    );
  };

  const handleDeleteProduct = (e, id) => {
    dispatch(
      openDialog({
        body: `Do you really want to delete this product?`,
        data: {
          closeButton: false,
          footer: true,
          confModal: true,
          title: "Delete Product",
          buttonText: {
            cancel: "No",
            submit: "Yes",
            cancelClass: "btn-danger",
          },
          cancelBtn: true,
          onSubmit: (e, closeModal) => {
            dispatch(httpDeleteProducts(id))
              .then((res) => {
                if (res?.payload?.status) {
                  toast.success(res?.payload?.msg);
                  getAllProductsData(search);
                } else {
                  toast.error(res?.payload?.msg || res?.payload);
                }
              })
              .catch((res) => toast.error(res?.payload));
            closeModal();
          },
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Products</h4>
          </div>
          <div className="d-flex align-items-center">
            {/* <div>
              <Link to="/">
                <button className="btn btn-primary">View Stock</button>
              </Link>
            </div> */}
            {/* <div>
              <FontAwesomeIcon
                className={styles.icon}
                icon="fa-solid fa-filter"
                color="#495057"
                onClick={() => navigate("/")}
              />
            </div> */}
            <div>
              <FontAwesomeIcon
                className={styles.icon}
                icon="fa-solid fa-gear"
                color="#495057"
                onClick={() => navigate("/products-setting")}
              />
            </div>
            <div>
              <FormInput
                dom={{
                  type: "text",
                  name: "search",
                  onChange: (e) => setSearch(e.target.value),
                  value: search,
                  placeholder: "Search",
                  className: styles.searchInput,
                }}
                fullwidth={true}
                postFix={() => (
                  <BiSearch
                    className={`${styles.icon} ${styles.absIcon} m-0`}
                  />
                )}
              />
            </div>
            <div>
              <button className="btn btn-primary" onClick={handleAddProduct}>
                Add Product
              </button>
            </div>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            {isLoading && (
              <div className="h-100 d-flex align-items-center justify-content-center">
                <Spinner className="m-1" color="info" />
              </div>
            )}
            {!isLoading && productsData && productsData?.length ? (
              <div className={`${styles.products}`}>
                {productsData?.map((dt, i) => (
                  <div key={i} className={styles.productCard}>
                    <div className={`${styles.newsHeader}`}>
                      <div className="d-flex align-items-center">
                        <FaEdit
                          className={`${styles.hoverIcon} ml-1`}
                          onClick={() => navigate(`/edit-product/${dt?._id}`)}
                        />
                        <AiFillDelete
                          className={`${styles.hoverIcon} ml-1`}
                          onClick={(e) => handleDeleteProduct(e, dt?._id)}
                        />
                      </div>
                    </div>
                    <div className={`${styles.productsItem}`}>
                      <img src={dt?.gallery?.[0]} alt="product" />
                    </div>
                    <p className={`mb-0 w-600 ${styles.productsDetail}`}>
                      {dt?.name}
                    </p>
                    <p className={`mb-0 w-600 ${styles.productsDetail}`}>
                      {dt?.prodType?.name}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              !isLoading && (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <h3>No Data Found</h3>
                </div>
              )
            )}
          </div>
        </div>
        <div>
          <Pagination
            paging={{
              count: totalCount,
              rows: 40,
              page: page,
            }}
            onPageClick={(page) => {
              setPage(page);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductsContainer;
