import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./sidebar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomePopover2 from "../form/CustomePopover2";
import FormInput from "../form/FormInput";
import { httpAddPageData } from "../../features/pageData/pageDataSlice";
import { httpGetAllModules } from "../../features/modules/modulesSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { openDialog } from "../../features/dialog/dialogSlice";
import {
  addAdminToLocalStorage,
  getAdminFromLocalStorage,
} from "../../utils/localStorage";
import {
  sidebarModules,
  allLinksSubject,
  sidebarCollpse,
} from "../../utils/globalVars";
import Tooltip from "rc-tooltip";
import { logoutAdmin } from "../../features/admin/adminSlice";

const Sidebar = ({ pathname, isCollapsed }) => {
  const dispatch = useDispatch();
  const [openMenu, setMenu] = useState("");
  const [newPage, setNewPage] = useState("");
  const [openState, setOpenState] = useState(false);
  const [modules, setModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { admin } = useSelector((store) => {
    return store.admin;
  });

  useEffect(() => {
    sidebarModules.subscribe(() => getModules());
    getModules();
  }, []);

  useEffect(() => {
    const pathArr = pathname.split("/");
    setMenu(`/${pathArr[1].replace(/\s+/g, "-").toLowerCase()}`);
  }, [pathname]);

  const getModules = () => {
    setIsLoading(true);
    dispatch(httpGetAllModules())
      .then((res) => {
        const admin = getAdminFromLocalStorage();
        const adminTemp = res?.payload?.modules;
        admin.modules = adminTemp;
        setModules(adminTemp);
        addAdminToLocalStorage(admin);
        allLinksSubject.next();
        toast.success(res.msg);
        setIsLoading(false);
      })
      .catch((res) => {
        toast.error(res.payload);
        setIsLoading(false);
      });
  };

  const handleAddPage = () => {
    const data = { name: newPage.trim() };
    dispatch(httpAddPageData(data))
      .then((res) => {
        toast.success(res.payload);
        setNewPage("");
        setOpenState(false);
        getModules();
      })
      .catch((res) => toast.error(res.payload));
  };

  const handlePages = () => {
    dispatch(
      openDialog({
        data: {
          pagesArr:
            modules && modules.length > 0
              ? modules.filter((d) => d.pageId && d.path !== "/home")
              : [],
          footer: false,
          title: "Pages",
          className: "Page-list",
        },
        path: "PagesList",
      })
    );
  };
  const handleLogout = () => {
    dispatch(logoutAdmin("Logging Out.."));
  };

  return (
    <>
      {!isCollapsed ? (
        <div id="sidebar" className={`${styles.sidebar} position-relative`}>
          <div
            className={`d-flex align-items-center justify-content-between m-3`}
          >
            <h1 className={`${styles.logoTxt} mb-0`}>INCRED CMS</h1>
            <div onClick={() => sidebarCollpse.next()} style={{ fontSize: 20 }}>
              <FontAwesomeIcon
                icon="fa-solid fa-angles-left"
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="module-list">
            <ul>
              {isLoading ? (
                <div
                  className="spinner-border text-primary mx-auto"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : modules && modules.length > 0 ? (
                modules
                  .filter((d) => !d.pageId || d.path === "/home")
                  .map((moduleItem) => (
                    <li
                      key={moduleItem._id}
                      data-link={moduleItem.path}
                      className={
                        openMenu &&
                        (openMenu === moduleItem.path ||
                          (moduleItem.children &&
                            moduleItem.children.some(
                              (el) => el.path === openMenu
                            )))
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        setMenu(
                          openMenu === moduleItem.path ||
                            openMenu === moduleItem._id
                            ? ""
                            : moduleItem._id
                        )
                      }
                    >
                      {moduleItem.path ? (
                        <Link
                          to={
                            moduleItem.pageId
                              ? `${moduleItem.path}/${moduleItem.pageId}`
                              : moduleItem.path
                          }
                          className={styles.sidebar__link}
                        >
                          <FontAwesomeIcon icon={moduleItem.icon} />
                          {/* <FontAwesomeIcon icon={"fa-regular fa-file"} />
                          <FontAwesomeIcon icon={"fa-solid fa-file"} /> */}
                          &nbsp; {moduleItem.name}&nbsp;
                          {moduleItem?.children && (
                            <FontAwesomeIcon
                              icon="fa-solid fa-caret-down"
                              className="ml-auto"
                            />
                          )}
                        </Link>
                      ) : (
                        <div
                          className={`${styles.sidebar__link} dropdown-sidebar`}
                        >
                          <FontAwesomeIcon icon={moduleItem.icon} />
                          &nbsp; {moduleItem.name}&nbsp;
                          {moduleItem?.children && (
                            <FontAwesomeIcon
                              icon="fa-solid fa-caret-down"
                              // className={moduleItem._id === openMenu ? "" : "rot180"}
                              className="ml-auto"
                            />
                          )}
                        </div>
                      )}
                      {(moduleItem._id === openMenu ||
                        moduleItem.path === openMenu) && (
                        <ul>
                          {moduleItem?.children?.map((child, i) => {
                            return (
                              <li
                                key={i}
                                className={`${
                                  openMenu && openMenu === child.path
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <Link
                                  to={child.path}
                                  className={styles.sidebar__link}
                                >
                                  <span
                                    className={`text-blue-200 text-xsmd ml-4 ${
                                      moduleItem._id === openMenu &&
                                      child.path === openMenu &&
                                      "nav-active font-medium"
                                    }`}
                                  >
                                    {child.name}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  ))
              ) : null}
              <li>
                <div
                  className={`${styles.sidebar__link} dropdown-sidebar`}
                  onClick={handlePages}
                >
                  <FontAwesomeIcon icon="fa-solid fa-file" />
                  &nbsp; Pages
                </div>
              </li>
              <li className={openState ? "active" : ""}>
                <CustomePopover2
                  positions={["right"]}
                  align={"center"}
                  openState={openState}
                  setOpenState={setOpenState}
                  content={
                    <div className={`${styles.popoverContent} d-flex`}>
                      <FormInput
                        dom={{
                          placeholder: "Add Page Name",
                          value: newPage,
                          onChange: (e) => setNewPage(e.target.value),
                        }}
                      />
                      <button
                        className="btn btn-primary ml-3"
                        onClick={handleAddPage}
                        disabled={!newPage}
                      >
                        Add
                      </button>
                    </div>
                  }
                >
                  <div className={`${styles.sidebar__link} dropdown-sidebar`}>
                    <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                    &nbsp; Add Page
                  </div>
                </CustomePopover2>
              </li>
              <li>
                <div
                  className={`${styles.sidebar__link} dropdown-sidebar`}
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
                  &nbsp; Logout
                </div>
              </li>
            </ul>
          </div>

          <div className={`${styles.userName}`}>
            <FontAwesomeIcon icon="fa-solid fa-user" />
            &nbsp; {admin?.name}
          </div>
        </div>
      ) : (
        <div
          id="sidebar-collapse"
          className={`${styles.sidebar} position-relative`}
        >
          <div
            className={`d-flex align-items-center my-4`}
            onClick={() => sidebarCollpse.next()}
          >
            <Tooltip placement="right" overlay={"Expand Menu"}>
              <FontAwesomeIcon
                icon="fa-solid fa-angles-right"
                className="margin-auto cursor-pointer"
              />
            </Tooltip>
          </div>

          <div className="module-list">
            <ul className="align-items-center">
              {isLoading ? (
                <div
                  className="spinner-border text-primary mx-auto"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : modules && modules.length > 0 ? (
                modules
                  .filter((d) => !d.pageId || d.path === "/home")
                  .map((moduleItem) => (
                    <li
                      key={moduleItem._id}
                      data-link={moduleItem.path}
                      className={
                        openMenu &&
                        (openMenu === moduleItem.path ||
                          (moduleItem.children &&
                            moduleItem.children.some(
                              (el) => el.path === openMenu
                            )))
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        setMenu(
                          openMenu === moduleItem.path ||
                            openMenu === moduleItem._id
                            ? ""
                            : moduleItem._id
                        )
                      }
                    >
                      {moduleItem.path ? (
                        <Link
                          to={
                            moduleItem.pageId
                              ? `${moduleItem.path}/${moduleItem.pageId}`
                              : moduleItem.path
                          }
                          className={`${styles.sidebar__link} justify-content-center`}
                        >
                          <Tooltip placement="right" overlay={moduleItem.name}>
                            <FontAwesomeIcon icon={moduleItem.icon} />
                          </Tooltip>
                        </Link>
                      ) : (
                        <div
                          className={`${styles.sidebar__link} justify-content-center dropdown-sidebar`}
                        >
                          <Tooltip placement="right" overlay={moduleItem.name}>
                            <FontAwesomeIcon icon={moduleItem.icon} />
                          </Tooltip>
                        </div>
                      )}
                    </li>
                  ))
              ) : null}
              <li>
                <div
                  className={`${styles.sidebar__link} justify-content-center dropdown-sidebar`}
                >
                  <Tooltip
                    placement="right"
                    overlay={"Pages"}
                    onClick={handlePages}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-file" />
                  </Tooltip>
                </div>
              </li>
              <li className={openState ? "active" : ""}>
                <CustomePopover2
                  positions={["right"]}
                  align={"center"}
                  openState={openState}
                  setOpenState={setOpenState}
                  content={
                    <div className={`${styles.popoverContent} d-flex`}>
                      <FormInput
                        dom={{
                          placeholder: "Add Page Name",
                          value: newPage,
                          onChange: (e) => setNewPage(e.target.value),
                        }}
                      />
                      <button
                        className="btn btn-primary ml-3"
                        onClick={handleAddPage}
                        disabled={!newPage}
                      >
                        Add
                      </button>
                    </div>
                  }
                >
                  <div
                    className={`${styles.sidebar__link} justify-content-center dropdown-sidebar`}
                  >
                    <Tooltip placement="right" overlay={"Add Page"}>
                      <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                    </Tooltip>
                  </div>
                </CustomePopover2>
              </li>

              <li>
                <div
                  className={`${styles.sidebar__link} justify-content-center dropdown-sidebar`}
                  onClick={handleLogout}
                >
                  <Tooltip placement="right" overlay={"Logout"}>
                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
                  </Tooltip>
                </div>
              </li>
            </ul>
          </div>

          <div className={styles.userName}>
            <Tooltip placement="right" overlay={admin?.name}>
              <FontAwesomeIcon
                icon="fa-solid fa-user"
                className="margin-auto"
              />
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
