import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllProductsThunk,
  getProductThunk,
  addProductThunk,
  updateProductThunk,
  deleteProductThunk,
} from "./productsThunk";

const initialState = {
  isLoading: false,
  allProducts: null,
  product: null,
};

export const httpGetAllProducts = createAsyncThunk(
  "products/allProducts",
  getAllProductsThunk
);

export const httpGetProduct = createAsyncThunk(
  "products/getProduct",
  getProductThunk
);

export const httpAddProduct = createAsyncThunk(
  "products/addProduct",
  addProductThunk
);

export const httpUpdateProduct = createAsyncThunk(
  "products/updateProduct",
  updateProductThunk
);

export const httpDeleteProducts = createAsyncThunk(
  "products/deleteProduct",
  deleteProductThunk
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllProducts.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllProducts.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.allProducts = payload.products;
    },
    [httpGetAllProducts.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpGetProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetProduct.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.product = payload.product;
    },
    [httpGetProduct.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpAddProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [httpAddProduct.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpAddProduct.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateProduct.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateProduct.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateProduct.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpDeleteProducts.pending]: (state) => {
      state.isLoading = true;
    },
    [httpDeleteProducts.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpDeleteProducts.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = productsSlice.actions;
export default productsSlice.reducer;
