import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllProductTypeThunk,
  addProductTypeThunk,
  updateProductTypeThunk,
  deleteProductTypeThunk,
} from "./productTypeThunk";

const initialState = {
  isLoading: false,
  productType: null,
};

export const httpGetAllProductType = createAsyncThunk(
  "productType/allType",
  getAllProductTypeThunk
);

export const httpAddProductType = createAsyncThunk(
  "productType/addType",
  addProductTypeThunk
);

export const httpUpdateProductType = createAsyncThunk(
  "productType/updateType",
  updateProductTypeThunk
);

export const httpDeleteProductType = createAsyncThunk(
  "productType/deleteType",
  deleteProductTypeThunk
);

const productTypeSlice = createSlice({
  name: "productType",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllProductType.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllProductType.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.emailIds = payload.productType;
    },
    [httpGetAllProductType.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpAddProductType.pending]: (state) => {
      state.isLoading = true;
    },
    [httpAddProductType.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpAddProductType.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpUpdateProductType.pending]: (state) => {
      state.isLoading = true;
    },
    [httpUpdateProductType.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpUpdateProductType.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [httpDeleteProductType.pending]: (state) => {
      state.isLoading = true;
    },
    [httpDeleteProductType.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      toast.success(payload);
    },
    [httpDeleteProductType.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = productTypeSlice.actions;
export default productTypeSlice.reducer;
