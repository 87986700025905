import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllEmailThunk = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/orderEmailId`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const addEmailThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/orderEmailId", data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateEmailThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/orderEmailId/${data?._id}`, data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteEmailThunk = async (productId, thunkAPI) => {
  try {
    const response = await IncredAPI.delete(`/orderEmailId/${productId}`);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
