import moment from "moment";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";

const FormDatePicker = (props) => {
  const {
    dom = {},
    label = "",
    error = "",
    errorMessage,
    errorClass = "",
    wrapperClass = "",
    containerclass = "",
    fullwidth = false,
    groupClass = "",
    value,
  } = props;

  const inputRef = React.createRef();
  useEffect(() => {
    dom.autoFocus && inputRef?.current?.focus();
  }, [dom.autoFocus, inputRef]);

  const handleDateChange = (date) => {
    const { name, inputChange, format = "YYYY-MM-DD" } = props;
    let finalDate = date ? moment(date).format(format) : "";
    inputChange({ target: { name, value: finalDate } });
  };

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  return (
    <div className={`${wrapperClass ? wrapperClass : ""}`}>
      {label && (
        <div className="px-0">
          <label>{label}</label>
          {dom.required && <small className="btn-danger ml-1">*</small>}
        </div>
      )}
      <div
        className={`d-flex align-items-center position-relative ${
          containerclass ? containerclass : ""
        }`}
      >
        <DatePicker
          popperClassName="custom-date"
          popperPlacement={dom.placement ? dom.placement : ""}
          placeholderText={dom.placeholder}
          className={`${error && "error-input"} ${
            groupClass ? "date-input" : "form-control input-style"
          } form-date-picker`}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          //maxDate={moment()}
          //dateFormat="DD-MM-YYYY"
          {...dom}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
          selected={
            value && isValidDate(new Date(value)) ? new Date(value) : null
          }
          onChange={handleDateChange}
        />
      </div>
      {error ? (
        <div className="input-error-message">
          <span className={`${errorClass}`}>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default FormDatePicker;
