import React, { useEffect, useState } from "react";
import styles from "../products/products.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openDialog } from "../../features/dialog/dialogSlice";
import { AiFillDelete } from "react-icons/ai";
import { TiPlus } from "react-icons/ti";
import { Spinner } from "reactstrap";
import {
  httpGetAllEmail,
  httpAddEmail,
  httpUpdateEmail,
  httpDeleteEmail,
} from "../../features/email/emailSlice";

function OrdersSettingContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailData, setEmailData] = useState([]);
  const [isELoading, setIsELoading] = useState(false);

  useEffect(() => {
    getAllEmailData();
  }, []);

  const getAllEmailData = () => {
    setIsELoading(true);
    dispatch(httpGetAllEmail())
      .then((res) => {
        const catArr = res?.payload?.emailIds;
        setEmailData(catArr);
        setIsELoading(false);
      })
      .catch((res) => {
        toast.error(res?.payload);
        setIsELoading(false);
      });
  };

  const handleAddEmail = (e, data) => {
    dispatch(
      openDialog({
        data: {
          footer: true,
          cancelBtn: true,
          title: data?._id ? "Edit Email" : "Add Email",
          titleClass: "fs20",
          buttonText: {
            submit: "Save",
          },
          name: data?.emailId || "",
          onSubmit: async (e, closeModal, modalData) => {
            const obj = {
              emailId: modalData?.data,
              _id: data?._id,
            };
            if (data?._id) {
              dispatch(httpUpdateEmail(obj))
                .then((res) => {
                  toast.success(res?.payload);
                  getAllEmailData();
                })
                .catch((res) => toast.error(res?.payload));
            } else {
              !data?._id && delete obj._id;
              dispatch(httpAddEmail(obj))
                .then((res) => {
                  toast.success(res?.payload);
                  getAllEmailData();
                })
                .catch((res) => toast.error(res?.payload));
            }
            closeModal();
          },
        },
        path: `AddCategory`,
      })
    );
  };

  const handleDeleteEmail = (e, id) => {
    dispatch(
      openDialog({
        body: `Do you really want to delete this email?`,
        data: {
          closeButton: false,
          footer: true,
          confModal: true,
          title: "Delete Email",
          buttonText: {
            cancel: "No",
            submit: "Yes",
            cancelClass: "btn-danger",
          },
          cancelBtn: true,
          onSubmit: (e, closeModal) => {
            const deleteId = id;
            dispatch(httpDeleteEmail(deleteId))
              .then((res) => {
                toast.success(res?.payload);
                getAllEmailData();
              })
              .catch((res) => toast.error(res?.payload));
            closeModal();
          },
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex flex-column overflow-hidden">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="page-title mb-0">Order settings</h4>
          </div>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-primary mx-3"
              onClick={() => navigate("/orders")}
            >
              Back
            </button>
          </div>
        </div>
        <div className="card mt-4 h-100 overflow-hidden">
          <div className="card-body h-100 overflow-auto">
            <div className={styles.productSettings}>
              <div>
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <div>
                    <h5 className={styles.fontBlack}>Email Ids</h5>
                  </div>
                  <div>
                    <TiPlus
                      className={`${styles.settingIcon} ml-3`}
                      onClick={(e) => handleAddEmail(e)}
                    />
                  </div>
                </div>
                <ul className="p-0">
                  {isELoading && (
                    <div>
                      <Spinner className="m-1" color="info" />
                    </div>
                  )}
                  {!isELoading && emailData && emailData?.length > 0 ? (
                    emailData?.map((data, i) => (
                      <li
                        className={`d-flex justify-content-between align-items-center mb-2 p-2`}
                        key={i}
                      >
                        <h6
                          className={`mb-0 ${styles.fontBlack}`}
                          style={{ textTransform: "lowercase" }}
                        >
                          {data?.emailId}
                        </h6>
                        <div>
                          <AiFillDelete
                            className={`${styles.settingIcon} ml-3`}
                            onClick={(e) => handleDeleteEmail(e, data?._id)}
                          />
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No Data Found</p>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OrdersSettingContainer;
