import axios from "axios";
import { getTokenFromLocalStorgae } from "./localStorage";
import { logoutAdmin } from "./../features/admin/adminSlice";
const API_BASE_URL = "https://api.incred.golf/";

const IncredAPI = axios.create({
  baseURL: API_BASE_URL,
});

IncredAPI.interceptors.request.use((config) => {
  const token = getTokenFromLocalStorgae();
  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

export const checkForUnauthorizedResponse = (error, thunkAPI, type) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(logoutAdmin());
    return thunkAPI.rejectWithValue("Unauthorized Logging Out...");
  }
  if (type === "addUpdateProd")
    return thunkAPI.rejectWithValue(error.response.data);
  return thunkAPI.rejectWithValue(error.response.data.msg);
};
export default IncredAPI;
