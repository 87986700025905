import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAllModulesThunk } from "./modulesThunk";

const initialState = {
  isLoading: false,
  modules: null,
};

export const httpGetAllModules = createAsyncThunk(
  "modules/getAllModules",
  getAllModulesThunk
);

const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    [httpGetAllModules.pending]: (state) => {
      state.isLoading = true;
    },
    [httpGetAllModules.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.modules = payload.modules;
    },
    [httpGetAllModules.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});

export const { showLoading, hideLoading } = modulesSlice.actions;
export default modulesSlice.reducer;
