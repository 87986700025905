import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";
// import { showLoading, hideLoading } from "./securitySlice";

export const getSectionsThunk = async (thunkAPI) => {
  try {
    const response = await IncredAPI.get("template");
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateSectionThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/template/${data.id}`, data.data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const uploadFileThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.post("/upload", data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
