import { createSlice } from "@reduxjs/toolkit";

const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    state: false,
    data: {},
    path: "",
  },
  reducers: {
    openDialog: (state, action) => {
      state.state = true;
      state.data = action?.payload?.data;
      state.path = action?.payload?.path;
      state.body = action?.payload?.body;
    },
    closeDialog: (state, action) => {
      state.state = false;
      state.data = {};
      state.path = "";
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
