import IncredAPI, { checkForUnauthorizedResponse } from "../../utils/IncredAPI";

export const getAllOrdersThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.get(
      `/orders${data.query ? data.query : ""}`
    );
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateOrderThunk = async (data, thunkAPI) => {
  try {
    const response = await IncredAPI.patch(`/order/${data._id}`, data);
    return response.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const viewOrderThunk = async (id, thunkAPI) => {
  try {
    const response = await IncredAPI.get(`/order/${id}`);
    return response.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
